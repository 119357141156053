import React, { Fragment, FC, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useTranslation } from "react-i18next"; // i18n
import { useGuestsContext } from "contexts/GuestsContext"; // GuestsContext

export interface GuestsInputProps {
  className?: string;
  maxGuests?: number; // Límite máximo de huéspedes
}

const GuestsInput: FC<GuestsInputProps> = ({ className = "flex-1", maxGuests = 10 }) => {
  const { t } = useTranslation("guestsInput");

  // Contexto global
  const context = useGuestsContext();
  const useContextValues = Boolean(context);

  const {
    adults: contextAdults = 2,
    children: contextChildren = 1,
    infants: contextInfants = 1,
    setAdults: contextSetAdults,
    setChildren: contextSetChildren,
    setInfants: contextSetInfants,
  } = context || {};

  // Estado local (fallback si no hay contexto)
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState<number>(contextAdults);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState<number>(contextChildren);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState<number>(contextInfants);

  const totalGuests =
    (useContextValues
      ? contextAdults + contextChildren + contextInfants
      : guestAdultsInputValue + guestChildrenInputValue + guestInfantsInputValue) || 0;

  const canAddMoreGuests = totalGuests < maxGuests; // Condición general para desactivar todos los incrementos

  const handleChangeData = (value: number, type: "guestAdults" | "guestChildren" | "guestInfants") => {
    const newTotal =
      type === "guestAdults"
        ? value + guestChildrenInputValue + guestInfantsInputValue
        : type === "guestChildren"
        ? guestAdultsInputValue + value + guestInfantsInputValue
        : guestAdultsInputValue + guestChildrenInputValue + value;

    if (newTotal > maxGuests) return; // Bloquea si excede el límite

    if (type === "guestAdults") {
      if (useContextValues && contextSetAdults) {
        contextSetAdults(value);
      } else {
        setGuestAdultsInputValue(value);
      }
    }

    if (type === "guestChildren") {
      if (useContextValues && contextSetChildren) {
        contextSetChildren(value);
      } else {
        setGuestChildrenInputValue(value);
      }
    }

    if (type === "guestInfants") {
      if (useContextValues && contextSetInfants) {
        contextSetInfants(value);
      } else {
        setGuestInfantsInputValue(value);
      }
    }
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
              open ? "shadow-lg" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests} {t("guests")}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? t("guests") : t("addGuests")}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    if (useContextValues) {
                      contextSetAdults?.(0);
                      contextSetChildren?.(0);
                      contextSetInfants?.(0);
                    } else {
                      setGuestAdultsInputValue(0);
                      setGuestChildrenInputValue(0);
                      setGuestInfantsInputValue(0);
                    }
                  }}
                />
              )}
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
              <NcInputNumber
                className="w-full"
                defaultValue={contextAdults || guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={canAddMoreGuests ? 10 : guestAdultsInputValue}
                min={1}
                label={t("adults")}
                desc={t("adultsDesc")}
                disabled={!canAddMoreGuests} // Deshabilitar si maxGuests alcanzado
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={contextChildren || guestChildrenInputValue}
                onChange={(value) => handleChangeData(value, "guestChildren")}
                max={canAddMoreGuests ? 4 : guestChildrenInputValue}
                min={0}
                label={t("children")}
                desc={t("childrenDesc")}
                disabled={!canAddMoreGuests} // Deshabilitar si maxGuests alcanzado
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={contextInfants || guestInfantsInputValue}
                onChange={(value) => handleChangeData(value, "guestInfants")}
                max={canAddMoreGuests ? 4 : guestInfantsInputValue}
                min={0}
                label={t("infants")}
                desc={t("infantsDesc")}
                disabled={!canAddMoreGuests} // Deshabilitar si maxGuests alcanzado
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
