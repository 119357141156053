import React, { createContext, useContext, useState } from "react";

// Define el tipo para el contexto
interface TotalSumContextType {
  totalSumInRange: number;
  setTotalSumInRange: (sum: number) => void;
}

// Crea el contexto con valores iniciales
const TotalSumContext = createContext<TotalSumContextType | undefined>(undefined);

// Proveedor del contexto
export const TotalSumProvider = ({ children }: { children: React.ReactNode }) => {
  const [totalSumInRange, setTotalSumInRange] = useState(0);

  return (
    <TotalSumContext.Provider value={{ totalSumInRange, setTotalSumInRange }}>
      {children}
    </TotalSumContext.Provider>
  );
};

// Hook para usar el contexto
export const useTotalSum = () => {
  const context = useContext(TotalSumContext);
  if (!context) {
    throw new Error("useTotalSum debe usarse dentro de un TotalSumProvider");
  }
  return context;
};
