import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import MapContainer from "containers/MapContainer";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import { useExperiencesFilterContext } from "contexts/ExperiencesFilterContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";

const ITEMS_PER_PAGE = 6;
const DEMO_EXPERIENCES = DEMO_EXPERIENCES_LISTINGS;

export interface SectionGridHasMapProps {
  isMapVisible: boolean;
  onToggleMap: () => void;
  mapCenter?: [number, number]; // centro del mapa
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  isMapVisible,
  onToggleMap,
  mapCenter,
}) => {
  const { t, i18n } = useTranslation("experiences");
  const { from, to } = useExperienceDatesContext();
  const { adults, children, infants } = useExperienceGuestsContext();
  const { location } = useExperienceLocationContext();

  const {
    priceRange,
    selectedTypes,
    selectedTimeOfDay,
    isSaleOffActive,
  } = useExperiencesFilterContext();

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detectar si es móvil
  const [filteredExperiences, setFilteredExperiences] = useState(DEMO_EXPERIENCES);
  const [visibleData, setVisibleData] = useState(filteredExperiences.slice(0, ITEMS_PER_PAGE));
  const [currentIndex, setCurrentIndex] = useState(ITEMS_PER_PAGE);

  // Manejar redimensionamiento de pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updatedExperiences = DEMO_EXPERIENCES.filter((experience) => {
      const numericPrice = parseFloat(experience.price.replace("$", ""));
      const matchesPriceRange = numericPrice >= priceRange[0] && numericPrice <= priceRange[1];
      const matchesSaleOff = !isSaleOffActive || experience.saleOff !== null;
      const matchesType =
        selectedTypes.length === 0 ||
        (experience.typeOfExperience && selectedTypes.includes(experience.typeOfExperience));
      const matchesTimeOfDay =
        selectedTimeOfDay.length === 0 ||
        (Array.isArray(experience.timeOfDayOptions)
          ? experience.timeOfDayOptions.some((time) => selectedTimeOfDay.includes(time))
          : selectedTimeOfDay.includes(experience.timeOfDayOptions));

      return matchesPriceRange && matchesSaleOff && matchesType && matchesTimeOfDay;
    });

    setFilteredExperiences(updatedExperiences);
    setVisibleData(updatedExperiences.slice(0, ITEMS_PER_PAGE));
    setCurrentIndex(ITEMS_PER_PAGE);
  }, [priceRange, selectedTypes, selectedTimeOfDay, isSaleOffActive]);

  const loadMoreItems = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + ITEMS_PER_PAGE;
      setVisibleData((prevVisible) => [
        ...prevVisible,
        ...filteredExperiences.slice(prevIndex, newIndex),
      ]);
      return newIndex;
    });
  };

  const handleScroll = () => {
    const margin = 300;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - margin) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [filteredExperiences, currentIndex]);

  const dateFormat: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFrom = from ? from.toLocaleDateString(i18n.language, dateFormat) : "";
  const formattedTo = to ? to.toLocaleDateString(i18n.language, dateFormat) : "";

  const totalGuests = adults + children + infants;

  // Mostrar solo el mapa en móviles cuando esté abierto
  if (isMobile && isMapVisible) {
    return (
      <div className="fixed inset-0 z-49 bg-white">
        <div className="w-full h-full z-[49]">
          <MapContainer
            currentHoverID={currentHoverID}
            DEMO_DATA={filteredExperiences.length ? filteredExperiences : []}
            listingType="experiences"
            mapCenter={mapCenter}
          />
          <ButtonClose
            onClick={onToggleMap}
            className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen">
      <div className="w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 relative z-20 overflow-y-auto">
        <Heading2
          onToggleMap={onToggleMap}
          isMapVisible={isMapVisible}
          heading={`${t("experiencesIn")} ${location || "Destino"}`}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {t("numberOfExperiences", { count: filteredExperiences.length })}
              <span className="mx-2">·</span>
              {formattedFrom} - {formattedTo}
              <span className="mx-2">·</span>
              {t("numberOfGuests", { count: totalGuests })}
            </span>
          }
        />
        <div className="mb-8 lg:mb-11">
          <TabFilters onMapClick={onToggleMap} isMapOpen={isMapVisible} />
        </div>
        <div className="grid grid-cols-1 gap-8">
          {visibleData.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => setCurrentHoverID(item.id)}
              onMouseLeave={() => setCurrentHoverID(-1)}
            >
              <ExperiencesCardH data={item} />
            </div>
          ))}
        </div>
      </div>

      {isMapVisible && (
        <div className="flex-grow xl:ml-8 h-full xl:h-[calc(100vh-88px)] sticky top-0">
          <div className="w-full h-full rounded-md overflow-hidden">
            <MapContainer
              currentHoverID={currentHoverID}
              DEMO_DATA={visibleData}
              listingType="experiences"
              mapCenter={mapCenter}
            />
            <ButtonClose
              onClick={onToggleMap}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
