import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

interface AuthContextProps {
  isAuthenticated: boolean;
  user: { avatar?: string } | null;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth debe ser usado dentro de AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<{ avatar?: string } | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      setUser({ avatar: "src/images/avatars/Image-1.png" });
    }
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    setUser({ avatar: "src/images/avatars/Image-1.png" });
    localStorage.setItem("token", "fakeToken123");
  };
  
  

  const logout = () => {
    // Lógica para cerrar sesión
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
