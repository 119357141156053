import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { useCurrency } from "contexts/CurrencyContext"; // Usar contexto para moneda
import { useTranslation } from "react-i18next";

export interface ExperiencesCardProps {
  className?: string;
  ratioClass?: string;
  data?: ExperiencesDataType;
  size?: "default" | "small";
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCard: FC<ExperiencesCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  ratioClass = "aspect-w-3 aspect-h-3",
}) => {
  const { t } = useTranslation("experiencesCard"); // Cargar traducciones

  const {
    galleryImgs,
    address = t("unknownAddress"),
    title = t("noTitle"),
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const { selectedCurrency } = useCurrency();

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === "number" ? price : parseFloat(price);

    if (isNaN(numericPrice) || !selectedCurrency.rate) {
      return `${selectedCurrency.symbol}N/A`;
    }

    const convertedPrice = numericPrice * selectedCurrency.rate;

    return convertedPrice % 1 === 0
      ? `${selectedCurrency.symbol}${Math.floor(convertedPrice)}`
      : `${selectedCurrency.symbol}${convertedPrice.toFixed(2)}`;
  };

  const renderSliderGallery = () => (
    <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
      <GallerySlider
        uniqueID={`ExperiencesCard_${id}`}
        ratioClass={ratioClass}
        galleryImgs={galleryImgs}
        href={href}
      />
      <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
      {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
    </div>
  );

  const renderContent = () => (
    <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
      <div className="space-y-2">
        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === "default" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0z"
              />
            </svg>
          )}
          <span>{address}</span>
        </div>

        <div className="flex items-center space-x-2">
          {isAds && <Badge name={t("ads")} color="green" />}
          <h2
            className={`font-medium capitalize ${
              size === "default" ? "text-base" : "text-base"
            }`}
          >
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
      </div>
      <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
      <div className="flex justify-between items-center">
        <span className="text-base font-semibold">
          {formatPrice(price)}
          {` `}
          {size === "default" && (
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              {t("perPerson")}
            </span>
          )}
        </span>
        <div className="flex items-center space-x-2">
          <StartRating reviewCount={reviewCount} point={reviewStart} />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default ExperiencesCard;
