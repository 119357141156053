import React, { FC, useEffect, useState, useRef } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import ExperiencesMoreFiltersMobile from "./TabFilters/ExperiencesMoreFiltersMobile";
import ExperiencesMobileFilterMenu from "./TabFilters/ExperiencesMobileFilterMenu";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from "react-i18next";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";
import { useExperiencesFilterContext } from "contexts/ExperiencesFilterContext";
import SectionGridHasMap from "./SectionGridHasMap";
import ExperiencesSortByMobile from "./TabFilters/ExperiencesSortByMobile";
import ExperiencesMapSearch from "./ExperiencesMapSearch";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: ExperiencesDataType[];
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS;
const ITEMS_PER_PAGE = 12;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t, i18n } = useTranslation("experiences");
  const { from, to } = useExperienceDatesContext();
  const { adults, children, infants } = useExperienceGuestsContext();
  const { location } = useExperienceLocationContext();
  const {
    priceRange,
    setPriceRange,
    selectedTypes,
    setSelectedTypes,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
    isSaleOffActive,
  } = useExperiencesFilterContext();

  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const tabFiltersRef = useRef<HTMLDivElement>(null);

  const [visibleData, setVisibleData] = useState<ExperiencesDataType[]>([]);
  const [currentIndex, setCurrentIndex] = useState(ITEMS_PER_PAGE);

  const [filteredData, setFilteredData] = useState<ExperiencesDataType[]>(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState<[number, number]>([37.3886, -5.9823]);
  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState("featured");

  const [isFullScreenMap, setIsFullScreenMap] = useState(false);

  const handleResize = () => setIsMobile(window.innerWidth < 1280);

  const handleScroll = () => {
    const margin = 300;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - margin) {
      loadMoreItems();
    }
  };

  const loadMoreItems = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + ITEMS_PER_PAGE;
      setVisibleData((prevData) => [
        ...prevData,
        ...filteredData.slice(prevIndex, newIndex),
      ]);
      return newIndex;
    });
  };

  useEffect(() => {
    setFilteredData(
      data.filter((experience) => {
        const numericPrice = parseFloat(experience.price.replace("$", ""));
        const matchesPriceRange = numericPrice >= priceRange[0] && numericPrice <= priceRange[1];
        const matchesSaleOff = !isSaleOffActive || experience.saleOff !== null;
        const matchesType =
          selectedTypes.length === 0 ||
          (experience.typeOfExperience && selectedTypes.includes(experience.typeOfExperience));
        const matchesTimeOfDay =
          selectedTimeOfDay.length === 0 ||
          experience.timeOfDayOptions?.some((time) => selectedTimeOfDay.includes(time));

        return matchesPriceRange && matchesSaleOff && matchesType && matchesTimeOfDay;
      })
    );
    setCurrentPage(1); // Reinicia la paginación al aplicar filtros
  }, [data, priceRange, selectedTypes, selectedTimeOfDay, isSaleOffActive]);

  useEffect(() => {
    setVisibleData(filteredData.slice(0, ITEMS_PER_PAGE));
    setCurrentIndex(ITEMS_PER_PAGE);
  }, [filteredData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData, currentIndex]);

  const dateFormat: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFrom = from
    ? new Date(from).toLocaleDateString(i18n.language, dateFormat)
    : "";
  const formattedTo = to
    ? new Date(to).toLocaleDateString(i18n.language, dateFormat)
    : "";

  const guestCount = adults + children + infants;

  if (isMapOpen && window.innerWidth < 768) {
    return (
      <div className="fixed inset-0 z-50 bg-white">
        <div className="absolute top-4 left-14 right-4 flex items-center justify-between z-[60]">
          <button
            onClick={() => setIsFiltersModalOpen(true)}
            className="bg-white border border-gray-300 text-gray-500 w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
            aria-label="Open filters"
          >
            <i className="las la-sliders-h text-2xl font-bold"></i>
          </button>

          <div className="flex-1 mx-4">
            <ExperiencesMapSearch
              onLocationSelect={(coordinates) => {
                setMapCenter(coordinates);
              }}
            />
          </div>

          <button
            onClick={() => setIsMapOpen(false)}
            className="bg-gray-500 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
            aria-label="Close map"
          >
            <span className="text-xl font-bold">✕</span>
          </button>
        </div>

        <div className="w-full h-full z-[10]">
          <SectionGridHasMap
            isMapVisible={true}
            onToggleMap={() => setIsMapOpen(false)}
            mapCenter={mapCenter}
          />
        </div>

        <ExperiencesMoreFiltersMobile
          isOpen={isFiltersModalOpen}
          closeModal={() => setIsFiltersModalOpen(false)}
          selectedTypes={selectedTypes}
          selectedTimeOfDay={selectedTimeOfDay}
          priceRange={priceRange}
          onApply={(types, timeOfDay, range) => {
            setSelectedTypes(types);
            setSelectedTimeOfDay(timeOfDay);
            setPriceRange(range);
            setIsFiltersModalOpen(false);
          }}
          onClear={() => {
            setSelectedTypes([]);
            setSelectedTimeOfDay([]);
            setPriceRange([0, 2000]);
            setIsFiltersModalOpen(false);
          }}
          className="fixed inset-0 z-[999] bg-white"
        />
      </div>
    );
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className} ${isMobile ? "pt-16" : ""
        }`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        onToggleMap={onToggleMap}
        isMapVisible={isMapVisible}
        heading={`${t("experiencesIn")} ${location || "Destino"}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {t("numberOfExperiences", { count: filteredData.length })}
            <span className="mx-2">·</span>
            {from && to ? `${formattedFrom} - ${formattedTo}` : t("dates")}
            <span className="mx-2">·</span>
            {t("numberOfGuests", { count: guestCount })}
          </span>
        }
      />

      {/* TabFilters */}
      {!isMobile && (
        <div className="w-full px-0 pb-8"> {/* Agregado pb-8 para padding inferior en pantallas grandes */}
          <TabFilters
            onMapClick={() => setIsFullScreenMap(true)} // Abre el mapa en pantalla completa
            isMapOpen={isFullScreenMap} // Controla el estado del mapa
          />
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {visibleData.map((experience: ExperiencesDataType) => (
          <ExperiencesCard key={experience.id} data={experience} />
        ))}
      </div>


    </div>

  );
};

export default SectionGridFilterCard;