import './i18n';
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { APIProvider } from "@vis.gl/react-google-maps";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./redux/store";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import { LocationProvider } from "./contexts/LocationContext";
import { DatesProvider } from "./contexts/DatesContext"; 
import { GuestsProvider } from 'contexts/GuestsContext';
import { FiltersProvider } from 'contexts/FilterContext';
import { ExperiencesFilterProvider } from 'contexts/ExperiencesFilterContext';
import { ExperienceLocationProvider } from 'contexts/ExperienceLocationContext';
import { ExperienceDatesProvider } from 'contexts/ExperienceDatesContext';
import { ExperienceGuestsProvider } from 'contexts/ExperienceGuestsContext';
import { TotalSumProvider } from 'contexts/TotalSumContext';
import { BrowserRouter } from "react-router-dom";
import { CurrencyProvider } from 'contexts/CurrencyContext';
import { SelectedCardProvider } from 'contexts/SelectedCardContext';
import { ScrollToSectionProvider } from 'contexts/ScrollToSectionContext';

// Componente para el fallback durante la carga de traducciones
const LoadingFallback = () => <div></div>;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<LoadingFallback />}>
        <HelmetProvider>
          <AuthProvider>
            <Provider store={store}>
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}>
                <LocationProvider>
                  <DatesProvider>
                    <GuestsProvider>
                      <FiltersProvider>
                        <ExperiencesFilterProvider>
                          <ExperienceLocationProvider>
                            <ExperienceDatesProvider>
                              <ExperienceGuestsProvider>
                                <TotalSumProvider>
                                  <CurrencyProvider>
                                    <SelectedCardProvider>
                                      <ScrollToSectionProvider>
                                        <App />
                                      </ScrollToSectionProvider>
                                    </SelectedCardProvider>
                                  </CurrencyProvider>
                                </TotalSumProvider>
                              </ExperienceGuestsProvider>
                            </ExperienceDatesProvider>
                          </ExperienceLocationProvider>
                        </ExperiencesFilterProvider>
                      </FiltersProvider>
                    </GuestsProvider>
                  </DatesProvider>
                </LocationProvider>
              </APIProvider>
            </Provider>
          </AuthProvider>
        </HelmetProvider>
      </Suspense>
      </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
