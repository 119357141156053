import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";
import DatePickerCustomDay from "./DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth2 from "components/DatePickerCustomHeaderTwoMonth2";
import { useDatesContext } from "contexts/DatesContext";

// Mover el objeto generado fuera para compartirlo
const generatedNumbers: { [key: string]: number } = {};

const SectionDateRange = ({
  onTotalSumChange,
  isSelected,
}: {
  onTotalSumChange: (sum: number) => void;
  isSelected: boolean;
  id?: string;
}) => {
  const { t, i18n } = useTranslation("sectionDateRange");
  const { from: startDate, to: endDate, setFrom: setStartDate, setTo: setEndDate } = useDatesContext();

  const currentLocale = i18n.language === "es" ? es : enUS;
  registerLocale(i18n.language, currentLocale);

  const calculateSumInRange = () => {
    if (startDate && endDate) {
      let sum = 0;
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dateKey = currentDate.toDateString();
        sum += generatedNumbers[dateKey] || 0;
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return sum;
    }
    return 0;
  };

  useEffect(() => {
    const totalSum = calculateSumInRange();
    onTotalSumChange(totalSum);
  }, [startDate, endDate]);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="listingSection__wrap overflow-hidden">
      {/* HEADING */}
      <div>
        <h2 className="text-2xl font-semibold">{t("availability")}</h2>
        {/* Condicional para cambiar el texto de availabilityDescription */}
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {isSelected ? t("availabilityDescription") : t("availabilityDescription2")}
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      {/* CONTENT */}
      <div
        style={{
          filter: isSelected ? "none" : "blur(5px)", // Aplica desenfoque si no hay tarjeta seleccionada
          pointerEvents: isSelected ? "auto" : "none", // Deshabilita interacciones cuando no está seleccionado
        }}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          minDate={new Date()}
          monthsShown={2}
          showPopperArrow={false}
          locale={i18n.language}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth2 {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay
              dayOfMonth={day}
              date={date}
              selected={!!(startDate && endDate && date && date >= startDate && date <= endDate)}
              generatedNumbers={generatedNumbers}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SectionDateRange;
