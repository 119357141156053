import React, { createContext, useContext, useState, ReactNode } from "react";
import {
    CurrencyDollarIcon,
    CurrencyEuroIcon,
    CurrencyPoundIcon,
    CurrencyRupeeIcon,
    BanknotesIcon,
  } from "@heroicons/react/24/outline";
  

// Definir la interfaz de la moneda
export interface Currency {
  id: string;
  name: string;
  symbol: string;
  rate: number;
  icon: React.ElementType;
  symbolPosition: "before" | "after";
}

  

// Lista de monedas
const currencies: Currency[] = [
  { id: "EUR", name: "currency.euro", symbol: "€", rate: 0.9, icon: CurrencyEuroIcon, symbolPosition: "after" },
  { id: "USD", name: "currency.dollar", symbol: "$", rate: 1, icon: CurrencyDollarIcon, symbolPosition: "before" },
  { id: "GBP", name: "currency.pound", symbol: "£", rate: 0.8, icon: CurrencyPoundIcon, symbolPosition: "before" },
  { id: "INR", name: "currency.rupee", symbol: "₹", rate: 74, icon: CurrencyRupeeIcon, symbolPosition: "before" },
  { id: "SAR", name: "currency.riyal", symbol: "﷼", rate: 3.75, icon: BanknotesIcon, symbolPosition: "before" },
];

// Crear el contexto
const CurrencyContext = createContext<{
  selectedCurrency: Currency;
  setSelectedCurrency: (currency: Currency) => void;
}>({
  selectedCurrency: currencies[0],
  setSelectedCurrency: () => {},
});

// Hook para usar el contexto
export const useCurrency = () => useContext(CurrencyContext);

// Proveedor del contexto
export const CurrencyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(() => {
    const storedCurrencyId = localStorage.getItem("currencyIsoCode");
    return currencies.find((c) => c.id === storedCurrencyId) || currencies[0];
  });

  // Función para formatear precios
  const formatPrice = (price: number): string => {
    const convertedPrice = price * selectedCurrency.rate;
    const formattedPrice = convertedPrice % 1 === 0 
      ? convertedPrice.toFixed(0) 
      : convertedPrice.toFixed(2);
  
    return selectedCurrency.symbolPosition === "before"
      ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
      : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
  };
  

  // Guardar la moneda en localStorage al cambiar
  const handleSetCurrency = (currency: Currency) => {
    setSelectedCurrency(currency);
    localStorage.setItem("currencyIsoCode", currency.id);
  };

  return (
    <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency: handleSetCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};