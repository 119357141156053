import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StayDataType } from "data/types"; // Ajusta la ruta si es necesario
import { useAuth } from "../contexts/AuthContext"; // Ruta correcta del AuthContext
import { useNavigate } from "react-router-dom"; // Para redirección

const LikeSaveBtns: React.FC<{ stay: StayDataType }> = ({ stay }) => {
  const { t } = useTranslation("likeSaveBtns");
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [isSaved, setIsSaved] = useState(false);

  const handleSave = () => {
    if (isAuthenticated) {
      // Alternar entre Guardar y Guardado
      setIsSaved((prev) => !prev);
    } else {
      navigate("/login");
    }
  };

  const handleShare = async () => {
    const shareData = {
      title: stay.title,
      text: t("shareMessage", { title: stay.title }),
      url: `${window.location.origin}/#/listing-stay-detail/${stay.id}`,
    };
  
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log("Contenido compartido exitosamente");
      } catch (error) {
        console.error("Error al compartir:", error);
      }
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(shareData.url);
        alert(t("linkCopied"));
      } catch (error) {
        console.error("Error al copiar el enlace:", error);
      }
    } else {
      alert(t("clipboardNotSupported"));
    }
  };
  

  return (
    <div className="flow-root relative">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        {/* Botón Compartir */}
        <span
          className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
          onClick={handleShare}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">{t("share")}</span>
        </span>

        {/* Botón Guardar */}
        <span
          className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
          onClick={handleSave}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill={isAuthenticated && isSaved ? "currentColor" : "none"}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">
            {isAuthenticated && isSaved ? t("saved") : t("save")}
          </span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;