import React, { FC } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

interface SimpleMapProps {
  lat: number;
  lng: number;
}

const SimpleMap: FC<SimpleMapProps> = ({ lat, lng }) => {
  // Configuración del icono personalizado usando L.Icon
  const customIcon = L.icon({
    iconUrl: "/location.svg", // Ruta pública
    iconSize: [30, 45],
    iconAnchor: [15, 45],
  });  

  return (
    <MapContainer
      center={[lat, lng]}
      zoom={13}
      style={{ width: "100%", height: "100%", borderRadius: "10px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Marcador con icono personalizado */}
      <Marker position={[lat, lng]} icon={customIcon} />
    </MapContainer>
  );
};

export default SimpleMap;