import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth2 from "components/DatePickerCustomHeaderTwoMonth2";
import DatePickerCustomDay from "../DatePickerCustomDay";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import i18n from "i18n";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // Español como ejemplo
import enUS from "date-fns/locale/en-US"; // Inglés

export interface StayDatesRangeInputProps {
  className?: string;
  isSelected: boolean; // Nuevo prop
}

// Mover el objeto generado fuera para compartirlo
const generatedNumbers: { [key: string]: number } = {};

registerLocale("es", es);
registerLocale("en-US", enUS);

const StayDatesRangeInput: React.FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  isSelected, // Usamos el nuevo prop
}) => {
  const { t } = useTranslation("stayDatesRangeInput");
  const { from: startDate, to: endDate, setFrom: setStartDate, setTo: setEndDate } = useDatesContext();

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const calculateSumInRange = () => {
    if (startDate && endDate) {
      let sum = 0;
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dateKey = currentDate.toDateString();
        sum += generatedNumbers[dateKey] || 0;
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return sum;
    }
    return 0;
  };

  const renderInput = () => {
    const currentLanguage = i18n.language || "en-US";

    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString(currentLanguage, {
              month: "short",
              day: "2-digit",
            }) || t("addDates")}
            {endDate
              ? " - " +
              endDate?.toLocaleDateString(currentLanguage, {
                month: "short",
                day: "2-digit",
              })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {t("checkInCheckOut")}
          </span>
        </div>
      </>
    );
  };


  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? "shadow-lg" : ""
              }`}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div
                className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8"
                style={{
                  filter: isSelected ? "none" : "blur(5px)", // Aplica desenfoque si no hay tarjeta seleccionada
                  pointerEvents: isSelected ? "auto" : "none", // Deshabilita interacciones cuando no está seleccionado
                }}
              >
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  minDate={new Date()}
                  inline
                  locale={i18n.language} // Usa el idioma actual
                  calendarStartDay={1} // Comienza la semana en lunes
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth2 {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay
                      dayOfMonth={day}
                      date={date}
                      selected={
                        !!(
                          startDate &&
                          endDate &&
                          date &&
                          date >= startDate &&
                          date <= endDate
                        )
                      }
                      generatedNumbers={generatedNumbers}
                    />
                  )}
                />

              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
