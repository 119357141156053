import { MapPinIcon } from "@heroicons/react/24/solid";
import Label from "components/Label/Label";
import { FC, useState, useEffect } from "react";
import axios from "axios";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

export interface PageAddListing2Props { }

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const { t } = useTranslation("pageAddListing2");

  const defaultPosition: LatLngExpression = [40.463667, -3.74922]; // España por defecto
  const [position, setPosition] = useState<LatLngExpression>(defaultPosition);
  const [zoom, setZoom] = useState<number>(5);
  const [countries, setCountries] = useState<string[]>([]);

  const [formData, setFormData] = useState({
    country: "",
    city: "",
    street: "",
    state: "",
    postalCode: "",
    roomNumber: "",
  });

  const customIcon = L.icon({
    iconUrl: `${process.env.PUBLIC_URL}/location.svg`,
    iconSize: [30, 45],
    iconAnchor: [15, 45],
  });

  useEffect(() => {
    const storedData = localStorage.getItem("pageAddListing2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFormData(parsedData.formData || formData);
      setPosition(parsedData.position || defaultPosition);
      setZoom(parsedData.zoom || 5);
    }

    // Obtener lista de países
    axios.get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data
          .map((country: any) => country.name.common)
          .sort();
        setCountries(countryNames);
      })
      .catch((error) => console.error("Error al obtener la lista de países:", error));
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "country") {
      geocodeCountry(value);
    }
  };

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const postalCode = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      postalCode,
    }));

    if (postalCode) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&postalcode=${encodeURIComponent(
            postalCode
          )}&country=${encodeURIComponent(formData.country)}`
        );

        if (response.data && response.data.length > 0) {
          const { lat, lon, display_name } = response.data[0];
          const city = response.data[0].address?.city || response.data[0].address?.town || response.data[0].address?.village || "";

          setPosition([parseFloat(lat), parseFloat(lon)]);
          setZoom(15); // Zoom cercano para la zona del código postal
          setFormData((prevData) => ({
            ...prevData,
            city, // Actualizamos la ciudad en el formulario
          }));
        }
      } catch (error) {
        console.error("Error al buscar la ubicación por código postal:", error);
      }
    }
  };


  const geocodeCountry = async (country: string) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(country)}`
      );
      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        setPosition([parseFloat(lat), parseFloat(lon)]);
        setZoom(6);
      }
    } catch (error) {
      console.error("Error al geocodificar el país:", error);
    }
  };

  const reverseGeocode = async (lat: number, lon: number) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      if (response.data) {
        const addr = response.data.address;
        setFormData((prev) => ({
          ...prev,
          country: addr.country || "",
          city: addr.city || addr.town || "",
          street: `${addr.road || ""} ${addr.house_number || ""}`.trim(),
          state: addr.state || "",
          postalCode: addr.postcode || "",
        }));
      }
    } catch (error) {
      console.error("Error al obtener la dirección inversa:", error);
    }
  };

  const RecenterMap = () => {
    const map = useMap();
    useEffect(() => {
      map.setView(position, zoom, { animate: true });
    }, [position, zoom, map]);
    return null;
  };

  const handleSaveData = () => {
    localStorage.setItem("pageAddListing2", JSON.stringify({ formData, position, zoom }));
  };

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
      onNext={handleSaveData}
    >
      <>
        <h2 className="text-2xl font-semibold">{t("pageTitle")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <ButtonSecondary
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  async (position) => {
                    const { latitude, longitude } = position.coords;
                    setPosition([latitude, longitude]);
                    setZoom(15);
                    await reverseGeocode(latitude, longitude);
                  },
                  (error) => {
                    const errorMessage: {
                      1: string;
                      2: string;
                      3: string;
                      default: string;
                    } = {
                      1: t("locationAccessDenied"),
                      2: t("locationUnavailable"),
                      3: t("locationTimeout"),
                      default: t("locationError"),
                    };

                    alert(errorMessage[error.code as 1 | 2 | 3] || errorMessage.default);
                  },
                  {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0,
                  }
                );
              } else {
                alert(t("geolocationNotSupported"));
              }
            }}
          >
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">{t("useCurrentLocation")}</span>
          </ButtonSecondary>

          <FormItem label={t("countryRegionLabel")}>
            <Select name="country" value={formData.country} onChange={handleInputChange}>
              <option value="">{t("selectCountryPlaceholder")}</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </FormItem>
          <FormItem label={t("streetLabel")}>
            <Input
              name="street"
              value={formData.street}
              onChange={handleInputChange}
              placeholder={t("streetPlaceholder")}
            />
          </FormItem>
          <FormItem label={t("roomNumberLabel")}>
            <Input
              name="roomNumber"
              value={formData.roomNumber}
              onChange={handleInputChange}
              placeholder={t("roomNumberPlaceholder")}
            />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label={t("stateLabel")}>
              <Input name="state" value={formData.state} onChange={handleInputChange} />
            </FormItem>
            <FormItem label={t("cityLabel")}>
              <Input name="city" value={formData.city} onChange={handleInputChange} />
            </FormItem>
            <FormItem label={t("postalCodeLabel")}>
              <Input
                name="postalCode"
                value={formData.postalCode}
                onChange={handlePostalCodeChange}
              />
            </FormItem>

          </div>

          <div>
            <Label>{t("detailedAddressLabel")}</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {formData.street}, {formData.city}, {formData.state}, {formData.country}
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <MapContainer center={position} zoom={zoom} style={{ width: "100%", height: "100%" }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <RecenterMap />
                    <Marker
                      position={position}
                      draggable
                      icon={customIcon}
                      eventHandlers={{
                        dragend: (e) => {
                          const newLatLng = e.target.getLatLng();
                          setPosition([newLatLng.lat, newLatLng.lng]);
                          reverseGeocode(newLatLng.lat, newLatLng.lng);
                        },
                      }}
                    />
                  </MapContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
