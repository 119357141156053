import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  selected?: boolean; // Indicador de si el día está seleccionado
}

// Objeto estático para almacenar los números aleatorios generados
const generatedNumbers: { [key: string]: number } = {};

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, selected }) => {
  if (!date) return null;

  // Crear una clave única para la fecha
  const dateKey = date.toDateString();

  // Generar y almacenar un número aleatorio solo si no existe para esta fecha
  if (!generatedNumbers[dateKey]) {
    generatedNumbers[dateKey] = Math.floor(Math.random() * (200 - 70 + 1)) + 70;
  }

  const randomNumber = generatedNumbers[dateKey];

  return (
    <span
      className="react-datepicker__day_span"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{dayOfMonth}</span>

    </span>
  );
};

export default DatePickerCustomDay;
