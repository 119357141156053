import React, { createContext, useContext, useEffect, useState } from "react";
import { openDB } from "idb";

interface DatesContextType {
  from: Date | null;
  to: Date | null;
  setFrom: (date: Date | null) => void;
  setTo: (date: Date | null) => void;
}

const DB_NAME = "DatesDB";
const STORE_NAME = "dates";

const DatesContext = createContext<DatesContextType | undefined>(undefined);

export const DatesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadDatesFromDB = async () => {
    const db = await initDB();
    const savedDates = await db.get(STORE_NAME, 1);
    return savedDates || { from: null, to: null };
  };

  const saveDatesToDB = async (dates: { from: Date | null; to: Date | null }) => {
    const db = await initDB();
    await db.put(STORE_NAME, {
      id: 1,
      from: dates.from ? dates.from.toISOString() : null,
      to: dates.to ? dates.to.toISOString() : null,
    });
  };

  const adjustDates = (from: Date | null, to: Date | null) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Aseguramos que solo se compare la fecha, no la hora
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 6); // Una semana después de hoy

    let adjustedFrom = from && from >= today ? from : today;
    let adjustedTo = to && to > adjustedFrom ? to : new Date(adjustedFrom);
    adjustedTo.setDate(adjustedFrom.getDate() + 6); // Asegura que sea una semana después de `from`

    return { from: adjustedFrom, to: adjustedTo };
  };

  useEffect(() => {
    const fetchDates = async () => {
      const savedDates = await loadDatesFromDB();
      const adjustedDates = adjustDates(
        savedDates.from ? new Date(savedDates.from) : null,
        savedDates.to ? new Date(savedDates.to) : null
      );
      setFrom(adjustedDates.from);
      setTo(adjustedDates.to);
      setIsLoading(false);
    };
    fetchDates();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveDatesToDB({ from, to });
    }
  }, [from, to, isLoading]);

  if (isLoading) {
    return null; // Renderiza un spinner mientras se cargan los datos
  }

  return (
    <DatesContext.Provider value={{ from, to, setFrom, setTo }}>
      {children}
    </DatesContext.Provider>
  );
};

export const useDatesContext = () => {
  const context = useContext(DatesContext);
  if (!context) {
    throw new Error("useDatesContext must be used within a DatesProvider");
  }
  return context;
};