import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { useCurrency } from "contexts/CurrencyContext";
import { useTranslation } from "react-i18next";

export interface StayCardHProps {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const { t } = useTranslation("stayCard");
  const { t: tTabFilters } = useTranslation("tabFilters"); // Namespace para las traducciones de tipos

  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    maxGuests,
    bedrooms,
    bathrooms,
    beds,
    type = "",
  } = data;

  const { selectedCurrency } = useCurrency();

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === "number" ? price : parseFloat(price);
    if (isNaN(numericPrice) || !selectedCurrency.rate) {
      return `${selectedCurrency.symbol}N/A`;
    }
    const convertedPrice = numericPrice * selectedCurrency.rate;
    const formattedPrice =
      convertedPrice % 1 === 0
        ? Math.floor(convertedPrice).toString()
        : convertedPrice.toFixed(2);
  
    return selectedCurrency.symbolPosition === "before"
      ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
      : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
  };
  

  // Mapeo de valores `type` a claves de traducción
  const typeMapping: { [key: string]: string } = {
    apartment: "apartment",
    house: "house",
    villa: "villa",
    hotel: "hotel",
    inn: "inn",
    motel: "motel",
    boat: "boat",
    sharedRoom: "sharedRoom",
    privateRoom: "privateRoom",
    hotelRoom: "hotelRoom",
  };

  // Obtener el nombre traducido del tipo de alojamiento
  const translatedType = typeMapping[type]
    ? tTabFilters(typeMapping[type])
    : tTabFilters("unknown");

  const renderSliderGallery = () => (
    <div className="relative flex-shrink-0 w-full md:w-72">
      <GallerySlider
        ratioClass="aspect-w-6 aspect-h-5"
        galleryImgs={galleryImgs}
        uniqueID={`StayCardH_${id}`}
        href={`/listing-stay-detail/${id}`}
      />
      <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
      {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
    </div>
  );

  const renderTienIch = () => (
    <div className="hidden sm:grid grid-cols-3 gap-2">
      <div className="space-y-3">
        <div className="flex items-center space-x-3">
          <i className="las la-user text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {maxGuests === 1
              ? t("guests", { count: 1 })
              : t("guests_plural", { count: maxGuests })}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-bed text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bedrooms === 1
              ? t("bedrooms", { count: 1 })
              : t("bedrooms_plural", { count: bedrooms })}
          </span>
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center space-x-3">
          <i className="las la-bath text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bathrooms === 1
              ? t("bathrooms", { count: 1 })
              : t("bathrooms_plural", { count: bathrooms })}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-bed text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {beds === 1
              ? t("beds", { count: 1 })
              : t("beds_plural", { count: beds })}
          </span>
        </div>
      </div>
    </div>
  );

  const renderContent = () => (
    <div className="flex-grow p-3 sm:p-5 flex flex-col">
      <div className="space-y-2">
        <div className="text-sm text-neutral-500 dark:text-neutral-400">
          <span>
            {translatedType} {t("in")} {address}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {isAds && <Badge name={t("ads")} color="green" />}
          <h2 className="text-lg font-medium capitalize">
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
      </div>
      <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
      {renderTienIch()}
      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
      <div className="flex justify-between items-end">
        <StartRating reviewCount={reviewCount} point={reviewStart} />
        <span className="text-base font-semibold text-secondary-500">
          {formatPrice(price)}
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            {t("perNight")}
          </span>
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={`/listing-stay-detail/${id}`} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
