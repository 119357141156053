import React, { ReactNode } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { useParams } from "react-router-dom";
import __stayListing from "../../data/jsons/__stayListing.json"
import type { ListingGalleryImage } from "data/types";

interface DetailPagetLayoutProps {
  children: ReactNode;
}

const DetailPagetLayout = ({ children }: DetailPagetLayoutProps) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const { id } = useParams();

  // Manejo del modal de galería de imágenes
  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  // Obtiene las imágenes correspondientes al tipo de detalle
  const getImageGalleryListing = (): ListingGalleryImage[] | undefined => {
    if (thisPathname?.includes("/listing-stay-detail")) {
      const stay = __stayListing.find((item) => item.id === id);
      return stay?.galleryImgs.map((img, index) => ({
        id: index,
        url: img,
      }));
    }
    if (thisPathname?.includes("/listing-car-detail")) {
      return listingCarImageGallery;
    }
    if (thisPathname?.includes("/listing-experiences-detail")) {
      return listingExperienceImageGallery;
    }

    return undefined;
  };

  return (
    <div className="ListingDetailPage">
      {/* Modal de galería de imágenes */}
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />


      {/* Contenido principal */}
      <div className="container ListingDetailPage__content">{children}</div>

      {/* Otras secciones */}
      <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
      </div>

      {/* Footer fijo para móviles */}
      <MobileFooterSticky />
    </div>
  );
};

export default DetailPagetLayout;