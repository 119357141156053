import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Avatar from "shared/Avatar/Avatar";
import { useCurrency } from "contexts/CurrencyContext";
import { useTranslation } from "react-i18next"; // Importar i18n

export interface ExperiencesCardHProps {
  className?: string;
  data?: ExperiencesDataType;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const { t } = useTranslation("experiencesCard");

  const {
    galleryImgs,
    address = t("unknownAddress"),
    title = t("noTitle"),
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    author,
    id,
    description,
    duration,
    maxGuests,
  } = data;

  const { selectedCurrency } = useCurrency();

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === "number" ? price : parseFloat(price);

    if (isNaN(numericPrice) || !selectedCurrency.rate) {
      return `${selectedCurrency.symbol}N/A`;
    }

    const convertedPrice = numericPrice * selectedCurrency.rate;

    return convertedPrice % 1 === 0
      ? `${selectedCurrency.symbol}${Math.floor(convertedPrice)}`
      : `${selectedCurrency.symbol}${convertedPrice.toFixed(2)}`;
  };

  const renderSliderGallery = () => (
    <div className="relative w-full md:w-72 flex-shrink-0 overflow-hidden">
      <GallerySlider
        ratioClass="aspect-w-12 aspect-h-9 md:aspect-h-11"
        galleryImgs={galleryImgs}
        uniqueID={`ExperiencesCardH_${id}`}
        href={href}
      />
      <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
      {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
    </div>
  );

  const renderContent = () => (
    <div className="flex-grow p-3 sm:p-5 flex flex-col">
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          {isAds && <Badge name={t("ads")} color="green" />}
          <h2 className="text-lg font-medium capitalize">
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
        <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
          <StartRating reviewCount={reviewCount} point={reviewStart} />
          <span>· </span>
          <div className="flex items-center">
            <span className="hidden sm:inline-block text-base">
              <i className="las la-map-marked"></i>
            </span>
            <span className="sm:ml-2"> {address}</span>
          </div>
        </div>
      </div>
      <div className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400 mt-4">
        <span className="line-clamp-2">{description}</span>
      </div>
      <div className="flex items-center space-x-8 mt-4">
        <div className="flex items-center space-x-2">
          <i className="las la-clock text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {duration === 1
              ? t("duration", { count: duration })
              : t("duration_plural", { count: duration })}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <i className="las la-user text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {maxGuests === 1
              ? t("upToPeople", { count: maxGuests })
              : t("upToPeople_plural", { count: maxGuests })}
          </span>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
      <div className="flex justify-between items-end">
        <div className="flex items-center space-x-3 text-sm text-neutral-700 dark:text-neutral-300">
          <Avatar imgUrl={author.avatar} userName={author.displayName} />
          <span className="hidden sm:inline-block">
            <span className="hidden sm:inline">{t("hostedBy")}</span>{" "}
            {author.displayName}
          </span>
        </div>
        <span className="text-base font-semibold text-secondary-700">
          {formatPrice(price)}
          {` `}
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            {t("perPerson")}
          </span>
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={`nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="ExperiencesCardH"
    >
      <Link to={href} className="absolute inset-0" />
      <div className="md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default ExperiencesCardH;
