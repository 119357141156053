import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import { useTotalSum } from "contexts/TotalSumContext";
import { useSelectedCard } from "contexts/SelectedCardContext";
import { useDatesContext } from "contexts/DatesContext";
import { useScrollToSection } from "contexts/ScrollToSectionContext"; // Importa el contexto

const MobileFooterSticky: React.FC = () => {
  const { totalSumInRange } = useTotalSum(); // Suma total
  const { selectedCard } = useSelectedCard(); // Tarjeta seleccionada
  const { from, to } = useDatesContext(); // Fechas
  const { t, i18n } = useTranslation("listingStayDetailPage");
  const { setScrollToSection } = useScrollToSection(); // Usar el contexto para el desplazamiento

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Estado para el mensaje de error

  const handleReserveClick = (openModal: () => void) => {
    if (!selectedCard.id) {
      setErrorMessage(t("mustSelectRoom")); // Mostrar mensaje de error
      setScrollToSection("sectionRooms"); // Solicitar desplazamiento a la sección de selección de tarjetas
    } else {
      setErrorMessage(null); // Limpiar mensaje de error si se selecciona una tarjeta
      openModal(); // Abrir el modal de reserva
    }
  };

  const formatDate = (date: Date | null): string => {
    if (!date) return "";
    return date.toLocaleDateString(i18n.language, {
      day: "2-digit",
      month: "short",
    });
  };

  return (
    <div
      className="block lg:hidden fixed bottom-12 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40"
      style={{
        marginBottom: "calc(1rem + env(safe-area-inset-bottom))", // Hace espacio para FooterNav
      }}
    >
      <div className="container flex items-center justify-between">
        {/* Izquierda: Total y Fecha */}
        <div className="flex-1">
          <span className="block text-xl font-semibold">
            <span className="text-sm font-normal text-neutral-500 dark:text-neutral-400">
              {t("total") + " "}
            </span>
            ${totalSumInRange}
          </span>
          <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {from && to
                  ? `${formatDate(from)} - ${formatDate(to)}`
                  : t("selectDates")}
              </span>
            )}
          />
        </div>

        {/* Centro: Título de la tarjeta o mensaje de error */}
        <div className="flex-1 text-center">
          {selectedCard.title ? (
            <span className="text-base font-medium text-neutral-500 dark:text-neutral-400">
              {selectedCard.title}
            </span>
          ) : errorMessage ? (
            <span className="text-xs text-red-500 dark:text-red-400">
              {errorMessage}
            </span>
          ) : null}
        </div>

        {/* Derecha: Botón de reserva */}
        <div className="flex-1 flex justify-end">
          <ModalReserveMobile
            renderChildren={({ openModal }) => (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                onClick={() => handleReserveClick(openModal)} // Lógica de validación
              >
                {t("reserve")}
              </ButtonPrimary>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileFooterSticky;