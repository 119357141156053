import { FC, useState, useEffect, useRef } from "react";
import { Marker, useMap } from "react-leaflet";
import { DivIcon } from "leaflet";
import L, { LeafletMouseEvent } from "leaflet";
import "leaflet.markercluster";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import StayCard from "components/StayCard/StayCard";
import styles from "./MapContainer.module.css";
import { useCurrency } from "contexts/CurrencyContext"; // Importamos el contexto de moneda

interface CustomMarkerLayerProps {
  currentHoverID: string | number;
  DEMO_DATA: (CarDataType | ExperiencesDataType | StayDataType)[];
  listingType: "car" | "experiences" | "stay";
}

const CustomMarkerLayer: FC<CustomMarkerLayerProps> = ({
  currentHoverID,
  DEMO_DATA,
  listingType,
}) => {
  const [selectedID, setSelectedID] = useState<string | number | null>(null);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);
  const map = useMap();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const { selectedCurrency } = useCurrency(); // Obtenemos la moneda seleccionada

  // Función para formatear el precio según la moneda seleccionada
  // Función para formatear el precio según la moneda seleccionada
const formatPrice = (price: number | string): string => {
  const numericPrice = typeof price === "number" ? price : parseFloat(price);

  // Validar si `price` y `rate` son válidos
  if (isNaN(numericPrice) || !selectedCurrency.rate) {
    return `${selectedCurrency.symbol}N/A`; // Mostrar "N/A" si hay algún problema
  }

  const convertedPrice = numericPrice * selectedCurrency.rate; // Convertir usando la tasa de cambio

  // Determinar si hay decimales significativos
  const formattedPrice =
    convertedPrice % 1 === 0
      ? `${selectedCurrency.symbol}${Math.floor(convertedPrice)}`
      : `${selectedCurrency.symbol}${convertedPrice.toFixed(2)}`;

  return formattedPrice;
};

  const getPixelPosition = (lat: number, lng: number) => {
    const point = map.latLngToContainerPoint([lat, lng]);
    return { x: point.x, y: point.y };
  };

  const handleMapClick = (e: LeafletMouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(e.originalEvent.target as Node)) {
      setSelectedID(null);
    }
  };

  useEffect(() => {
    if (selectedID) {
      map.dragging.disable();
      map.scrollWheelZoom.disable();
      map.doubleClickZoom.disable();
      map.on("click", handleMapClick);
    } else {
      map.dragging.enable();
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
      map.off("click", handleMapClick);
    }

    return () => {
      map.dragging.enable();
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
      map.off("click", handleMapClick);
    };
  }, [selectedID, map]);

  const handleMarkerClick = (item: StayDataType | CarDataType | ExperiencesDataType) => {
    setSelectedID((prevID) => {
      const newID = prevID === item.id ? null : item.id;
      if (newID !== null) {
        const pixelPosition = getPixelPosition(item.map.lat, item.map.lng);
        setPosition(pixelPosition);
      }
      return newID;
    });
  };

  useEffect(() => {
    const markerClusterGroup = L.markerClusterGroup();

    DEMO_DATA.forEach((item) => {
      const { lat, lng } = item.map;

      // Icono personalizado de Leaflet con el precio formateado
      const customIcon = new DivIcon({
        html: `<div class="${styles.customMarker}"><div class="${styles.priceBox}">${formatPrice(item.price)}</div></div>`,
        className: styles.customIcon,
      });

      const marker = L.marker([lat, lng], { icon: customIcon });
      marker.on("click", () => handleMarkerClick(item));
      markerClusterGroup.addLayer(marker);
    });

    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [DEMO_DATA, map, selectedCurrency]); // Dependencia de selectedCurrency para actualizar precios al cambiar moneda

  return (
    <>
      {selectedID && position && (
        <div
          ref={cardRef}
          style={{
            position: "absolute",
            zIndex: 1000,
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <StayCard
            data={DEMO_DATA.find((item) => item.id === selectedID) as StayDataType}
            size="small"
            className={styles.stayCard}
          />
        </div>
      )}
    </>
  );
};

export default CustomMarkerLayer;
