import React, { createContext, useContext, useState, ReactNode } from "react";

interface SelectedCardContextType {
  selectedCard: { id: string | null; title: string | null };
  setSelectedCard: React.Dispatch<
    React.SetStateAction<{ id: string | null; title: string | null }>
  >;
}

const SelectedCardContext = createContext<SelectedCardContextType | undefined>(
  undefined
);

export const SelectedCardProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCard, setSelectedCard] = useState<{
    id: string | null;
    title: string | null;
  }>({ id: null, title: null });

  return (
    <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard }}>
      {children}
    </SelectedCardContext.Provider>
  );
};

export const useSelectedCard = () => {
  const context = useContext(SelectedCardContext);
  if (!context) {
    throw new Error(
      "useSelectedCard must be used within a SelectedCardProvider"
    );
  }
  return context;
};
