import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useState, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useTranslation } from "react-i18next";

export interface PageAddListing3Props {}

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const { t } = useTranslation("pageAddListing3"); // Uso de traducciones
  const [formData, setFormData] = useState({
    acreage: "",
    guests: 4,
    bedroom: 4,
    beds: 4,
    bathroom: 2,
  });

  // Cargar datos guardados de localStorage
  useEffect(() => {
    const savedData = localStorage.getItem("listingDataPage3");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Guardar datos en localStorage al cambiar el formulario
  const handleSaveData = () => {
    localStorage.setItem("listingDataPage3", JSON.stringify(formData));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Permitir solo números y máximo de 5 caracteres
    if (/^\d{0,5}$/.test(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleNumberChange = (name: string, value: number) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
      onNext={handleSaveData} // Guardar datos al hacer clic en continuar
    >
      <>
        <h2 className="text-2xl font-semibold">{t("sizeOfYourLocation")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem label={t("acreage")}>
            <input
              type="text"
              name="acreage"
              value={formData.acreage}
              onChange={handleInputChange}
              maxLength={5}
              className="w-full px-4 py-2 border border-neutral-200 rounded-md"
              placeholder={t("selectAcreage")}
            />
          </FormItem>
          <NcInputNumber
            label={t("maxGuests")}
            defaultValue={formData.guests}
            onChange={(value) => handleNumberChange("guests", value)}
          />
          <NcInputNumber
            label={t("bedrooms")}
            defaultValue={formData.bedroom}
            onChange={(value) => handleNumberChange("bedroom", value)}
          />
          <NcInputNumber
            label={t("beds")}
            defaultValue={formData.beds}
            onChange={(value) => handleNumberChange("beds", value)}
          />
          <NcInputNumber
            label={t("bathrooms")}
            defaultValue={formData.bathroom}
            onChange={(value) => handleNumberChange("bathroom", value)}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
