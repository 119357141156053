import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useTranslation } from "react-i18next";

export interface PageAddListing1Props { }

const propertyOptions = [
  { value: "inn", labelKey: "inn", descriptionKey: "innDescription" },
  { value: "motel", labelKey: "motel", descriptionKey: "motelDescription" },
  { value: "boat", labelKey: "boat", descriptionKey: "boatDescription" },
  { value: "hotel", labelKey: "hotel", descriptionKey: "hotelDescription" },
  { value: "house", labelKey: "house", descriptionKey: "houseDescription" },
  { value: "villa", labelKey: "villa", descriptionKey: "villaDescription" },
  { value: "apartment", labelKey: "apartment", descriptionKey: "apartmentDescription" },
];

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const { t } = useTranslation("pageAddListing1");

  const [formData, setFormData] = useState({
    propertyType: "",
    placeName: "",
    rentalForm: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("pageAddListing1");
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      localStorage.setItem("pageAddListing1", JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const handleNext = () => {
    localStorage.setItem("pageAddListing1", JSON.stringify(formData));
  };

  const selectedPropertyDescription =
    formData.propertyType && t(`${formData.propertyType}Description`);


  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
      onNext={handleNext}
    >
      <>
        <h2 className="text-2xl font-semibold">{t("choosePropertyType")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem
            label={t("choosePropertyType")}
            desc={selectedPropertyDescription || ""}
          >
            <Select
              name="propertyType"
              value={formData.propertyType}
              onChange={handleInputChange}
            >
              {propertyOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {t(option.labelKey)}
                </option>
              ))}
            </Select>

          </FormItem>
          <FormItem
            label={t("placeNameLabel")}
            desc={t("placeNameDesc")}
          >
            <Input
              name="placeName"
              value={formData.placeName}
              onChange={handleInputChange}
              placeholder={t("placeNamePlaceholder")}
              className={`${formData.placeName ? "" : "text-gray-400"}`}
            />
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
