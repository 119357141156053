import React, { FC } from "react";
import { useCurrency } from "contexts/CurrencyContext"; // Importar el contexto de moneda

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  selected?: boolean; // Indicador de si el día está seleccionado
  generatedNumbers: { [key: string]: number }; // Objeto compartido para almacenar los números
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, selected, generatedNumbers }) => {
  const { selectedCurrency } = useCurrency(); // Obtener la moneda seleccionada del contexto

  if (!date) return null;

  // Crear una clave única para la fecha
  const dateKey = date.toDateString();

  // Generar y almacenar un número aleatorio solo si no existe para esta fecha
  if (!generatedNumbers[dateKey]) {
    generatedNumbers[dateKey] = Math.floor(Math.random() * (200 - 70 + 1)) + 70;
  }

  // Obtener el número generado
  const randomNumber = generatedNumbers[dateKey];

  // Formatear el número según la moneda seleccionada
  const formatPrice = (price: number): string => {
    const convertedPrice = price * selectedCurrency.rate; // Convertir usando la tasa de cambio
    
    // Formatear el precio: sin decimales si son `00`, con dos decimales si no
    const formattedPrice =
      convertedPrice % 1 === 0
        ? Math.floor(convertedPrice).toString()
        : convertedPrice.toFixed(2);
  
    return selectedCurrency.symbolPosition === "before"
      ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
      : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
  };
  

  return (
    <span
      className="react-datepicker__day_span"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{dayOfMonth}</span>
      <small
        style={{
          fontSize: "0.6em", // Tamaño reducido para el dinero
          marginTop: "2px",
        }}
      >
        {formatPrice(randomNumber)} {/* Mostrar el precio formateado */}
      </small>
    </span>
  );
};

export default DatePickerCustomDay;