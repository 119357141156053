import React, { createContext, useContext, useState, ReactNode } from "react";

interface ScrollToSectionContextType {
  scrollToSection: string | null;
  setScrollToSection: (section: string | null) => void;
}

const ScrollToSectionContext = createContext<ScrollToSectionContextType | undefined>(undefined);

export const ScrollToSectionProvider = ({ children }: { children: ReactNode }) => {
  const [scrollToSection, setScrollToSection] = useState<string | null>(null);

  return (
    <ScrollToSectionContext.Provider value={{ scrollToSection, setScrollToSection }}>
      {children}
    </ScrollToSectionContext.Provider>
  );
};

export const useScrollToSection = () => {
  const context = useContext(ScrollToSectionContext);
  if (!context) {
    throw new Error("useScrollToSection must be used within a ScrollToSectionProvider");
  }
  return context;
};
