import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import { useGuestsContext } from "contexts/GuestsContext";
import { useDatesContext } from "contexts/DatesContext";
import StayDatesRangeInput from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";
import { useSelectedCard } from "contexts/SelectedCardContext";
import __stayListing from "../../data/jsons/__stayListing.json";
import { useParams } from "react-router-dom";
import __rooms from "../../data/jsons/__rooms.json";
import { RoomDataType } from "data/types";
import { useTotalSum } from "contexts/TotalSumContext";
import { useCurrency } from "contexts/CurrencyContext";
import { StarIcon } from "@heroicons/react/24/solid";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({ className = "" }) => {
  const { t } = useTranslation("checkOutPage");
  const { id } = useParams(); // Obtener el ID del alojamiento desde los parámetros de la URL

  // Llamar los hooks al inicio, antes de cualquier retorno
  const { selectedCard } = useSelectedCard();
  const { from, to } = useDatesContext();
  const { adults, children, infants } = useGuestsContext();

  // Buscar alojamiento por ID
  const stay = __stayListing.find((item) => item.id === id);

  const { selectedCurrency } = useCurrency();
  // Obtener el total sum desde el contexto
  const { totalSumInRange } = useTotalSum();

  // Filtrar habitaciones asociadas
  const associatedRooms = stay
    ? (stay.rooms as string[])
      .map((roomId) => __rooms.find((room) => room.id === roomId))
      .filter((room): room is RoomDataType => !!room)
    : [];

  // Total de huéspedes
  const totalGuests = adults + children + infants;

  // Validar si no se encuentra el alojamiento
  if (!stay) {
    return <div>{t("noPropertyFound")}</div>;
  }

  const handleExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Eliminar cualquier carácter que no sea un número
    value = value.replace(/[^0-9]/g, '');

    // Limitar a 4 caracteres
    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    // Insertar la barra "/" después de los dos primeros números
    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }

    // Establecer el valor formateado en el campo
    e.target.value = value;
  };

  const handleCvcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Eliminar cualquier carácter que no sea un número
    value = value.replace(/[^0-9]/g, '');

    // Limitar a un máximo de 3 caracteres
    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    // Establecer el valor validado en el campo
    e.target.value = value;
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Eliminar cualquier carácter que no sea un número
    value = value.replace(/[^0-9]/g, '');

    // Limitar la longitud a 12 números
    if (value.length > 12) {
      value = value.slice(0, 12);
    }

    // Insertar un espacio después de cada 3 números
    value = value.match(/.{1,3}/g)?.join(' ') || '';

    // Establecer el valor formateado en el campo
    e.target.value = value;
  };

  const handleCardHolderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Permitir solo letras y espacios
    value = value.replace(/[^a-zA-Z\s]/g, '');

    // Limitar a 40 caracteres
    if (value.length > 40) {
      value = value.slice(0, 40);
    }

    // Establecer el valor validado en el campo
    e.target.value = value;
  };

  const renderSidebar = () => {
    // Buscar la habitación seleccionada
    const selectedRoom = selectedCard?.id
      ? associatedRooms.find((room) => room.id === selectedCard.id)
      : null;

    // Función para formatear precios
    const formatPrice = (price: number): string => {
      const convertedPrice = price * selectedCurrency.rate; // Convertir usando la tasa de cambio
      
      // Formatear el precio: sin decimales si son `00`, con dos decimales si no
      const formattedPrice =
        convertedPrice % 1 === 0
          ? Math.floor(convertedPrice).toString()
          : convertedPrice.toFixed(2);
    
      return selectedCurrency.symbolPosition === "before"
        ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
        : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
    };
    

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        {/* Información del Alojamiento y Habitación */}
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt={selectedRoom?.title || stay.title}
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={
                  selectedRoom?.featuredImage || // Imagen de la habitación seleccionada
                  stay.featuredImage || // Imagen destacada del alojamiento
                  (stay.galleryImgs && stay.galleryImgs[0]) || // Primera imagen de la galería
                  "https://via.placeholder.com/400" // Imagen por defecto
                }
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {stay.title} {/* Nombre del alojamiento */}
              </span>
              <span className="text-base font-medium mt-1 block">
                {selectedCard?.title || t("noRoomSelected")} {/* Nombre de la habitación seleccionada */}
              </span>
            </div>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              {stay.address} {/* Ubicación del alojamiento */}
            </span>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex items-center space-x-2">
              {/* Estrellas */}
              <div className="flex space-x-1">
                <StarIcon className="w-[16px] h-[16px] text-orange-500" />
                <StarIcon className="w-[16px] h-[16px] text-orange-500" />
                <StarIcon className="w-[16px] h-[16px] text-orange-500" />
                <StarIcon className="w-[16px] h-[16px] text-orange-500" />
                <StarIcon
                  className="w-[16px] h-[16px] text-orange-500"
                  style={{ clipPath: "inset(0 50% 0 0)" }}
                />
              </div>

              {/* Número de reseñas */}
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                (112)
              </span>
            </div>
          </div>
        </div>

        {/* Detalles de Precio */}
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">{t("priceDetail")}</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("stayCharge")}</span>
            <span>{formatPrice(totalSumInRange)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("serviceCharge")}</span>
            <span>{formatPrice(0)}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("total")}</span>
            <span>{formatPrice(totalSumInRange)}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          {t("confirmAndPayment")}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">{t("yourTrip")}</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-row divide-x divide-neutral-200 dark:divide-neutral-700 overflow-visible relative z-10">
            {/* Stay Dates Range Input */}
            <div className="flex-1 p-5 relative pointer-events-none">
              <StayDatesRangeInput
                className="w-full z-[50]"
                isSelected={true}
              />
            </div>

            {/* Guests Input */}
            <div className="flex-1 p-5 relative pointer-events-none">
              <GuestsInput className="w-full z-[50]" maxGuests={10} />
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-2xl font-semibold">{t("payWith")}</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center justify-center focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      <span className="mr-2.5">{t("creditCard")}</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img className="w-8" src={mastercardPng} alt="mastercard" />
                    </button>

                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>{t("cardNumber")}</Label>
                    <Input
                      type="text"
                      placeholder="111 112 222 999"
                      maxLength={15} // 12 números + 3 espacios
                      onChange={(e) => handleCardNumberChange(e)}
                    />
                  </div>

                  <div className="space-y-1">
                    <Label>{t("cardHolder")}</Label>
                    <Input
                      type="text"
                      placeholder="JOHN DOE"
                      maxLength={40} // Limita la longitud máxima a 40 caracteres
                      onChange={(e) => handleCardHolderChange(e)}
                    />
                  </div>

                  <div className="flex space-x-5">
                    <div className="flex-1 space-y-1">
                      <Label>{t("expirationDate")}</Label>
                      <Input
                        type="text"
                        placeholder="MM/YY"
                        maxLength={5} // Incluye el carácter "/"
                        onChange={(e) => handleExpirationDateChange(e)}
                      />
                    </div>

                    <div className="flex-1 space-y-1">
                      <Label>{t("cvc")}</Label>
                      <Input
                        type="text"
                        placeholder="123"
                        maxLength={3} // Limita la longitud máxima a 3 caracteres
                        onChange={(e) => handleCvcChange(e)}
                      />
                    </div>

                  </div>
                  <div className="space-y-1">
                    <Label>{t("messageForAuthor")}</Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      {t("writeAboutYourself")}
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>{t("email")}</Label>
                    <Input type="email" placeholder="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>{t("password")}</Label>
                    <Input type="password" placeholder="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>{t("messageForAuthor")}</Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      {t("writeAboutYourself")}
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>

            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary href={"/pay-done"}>{t("confirmAndPay")}</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;