import React, { FC, useState, useEffect } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import CommonLayout from "./CommonLayout";
import { useTranslation } from "react-i18next";

const facilities = [
  { name: "parking", labelKey: "parking" },
  { name: "pets_allowed", labelKey: "pets_allowed" },
  { name: "non_smoking_rooms", labelKey: "non_smoking_rooms" },
  { name: "pool", labelKey: "pool" },
  { name: "free_wifi", labelKey: "free_wifi" },
  { name: "terrace", labelKey: "terrace" },
  { name: "kitchen", labelKey: "kitchen" },
  { name: "private_bathrooms", labelKey: "private_bathrooms" },
];

const amenities = [
  { name: "coffeMaker", labelKey: "coffeMaker" },
  { name: "oven", labelKey: "oven" },
  { name: "microwave", labelKey: "microwave" },
  { name: "hairDryer", labelKey: "hairDryer" },
  { name: "washingMachine", labelKey: "washingMachine" },
  { name: "jacuzzi", labelKey: "jacuzzi" },
];

const PageAddListing4: FC = () => {
  const { t } = useTranslation("pageAddListing4"); // Traducciones
  const [formData, setFormData] = useState<{ [key: string]: boolean }>({});

  // Cargar datos guardados de localStorage
  useEffect(() => {
    const savedData = localStorage.getItem("listingDataPage4");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    } else {
      const initialData = [...facilities, ...amenities].reduce((acc, item) => {
        acc[item.name] = false;
        return acc;
      }, {} as { [key: string]: boolean });
      setFormData(initialData);
    }
  }, []);

  // Guardar datos en localStorage al actualizar
  const handleSaveData = () => {
    localStorage.setItem("listingDataPage4", JSON.stringify(formData));
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
      onNext={handleSaveData} // Guardar datos al hacer clic en continuar
    >
      <>
        {/* Facilities Section */}
        <div>
          <h2 className="text-2xl font-semibold">{t("facilities")}</h2>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {facilities.map(({ name, labelKey }) => (
              <Checkbox
                key={name}
                label={t(labelKey)}
                name={name}
                checked={formData[name] || false}
                onChange={(checked) => handleCheckboxChange(name, checked)}
              />
            ))}
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-8"></div>

        {/* Amenities Section */}
        <div>
          <h2 className="text-2xl font-semibold">{t("amenities")}</h2>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {amenities.map(({ name, labelKey }) => (
              <Checkbox
                key={name}
                label={t(labelKey)}
                name={name}
                checked={formData[name] || false}
                onChange={(checked) => handleCheckboxChange(name, checked)}
              />
            ))}
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
