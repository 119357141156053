import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useTranslation } from "react-i18next";

export interface PageAddListing5Props {}

type FormDataKeys = "Pet" | "PartyOrganizing" | "Smoking";

const PageAddListing5: FC<PageAddListing5Props> = () => {
  const { t } = useTranslation("pageAddListing5"); // Traducciones
  const [formData, setFormData] = useState({
    Pet: "",
    PartyOrganizing: "",
    Smoking: "",
  });
  const [additionalRules, setAdditionalRules] = useState<string[]>([]);
  const [newRule, setNewRule] = useState("");

  // Cargar datos desde localStorage al iniciar
  useEffect(() => {
    const storedData = localStorage.getItem("pageAddListing5");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const { additionalRules: savedRules, ...savedFormData } = parsedData;
      setFormData(savedFormData);
      setAdditionalRules(Array.isArray(savedRules) ? savedRules : []); // Validar que sea array
    }
  }, []);

  // Guardar datos en localStorage
  const saveDataToLocalStorage = () => {
    localStorage.setItem(
      "pageAddListing5",
      JSON.stringify({ ...formData, additionalRules })
    );
  };

  // Manejar cambios en los radio buttons
  const handleRadioChange = (name: FormDataKeys, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Manejar cambios en el input para nuevas reglas
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRule(e.target.value);
  };

  // Añadir una nueva regla
  const handleAddRule = () => {
    if (newRule.trim() !== "") {
      setAdditionalRules((prevRules) => [...prevRules, newRule]);
      setNewRule(""); // Limpiar el input
    }
  };

  // Eliminar una regla existente
  const handleRemoveRule = (index: number) => {
    setAdditionalRules((prevRules) =>
      prevRules.filter((_, ruleIndex) => ruleIndex !== index)
    );
  };

  // Renderizar opciones de radio buttons
  const renderRadio = (name: FormDataKeys, id: string, label: string) => (
    <div className="flex items-center">
      <input
        checked={formData[name] === id}
        id={id + name}
        name={name}
        type="radio"
        className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        onChange={() => handleRadioChange(name, id)}
      />
      <label
        htmlFor={id + name}
        className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
      >
        {label}
      </label>
    </div>
  );

  return (
    <CommonLayout
      index="05"
      backtHref="/add-listing-4"
      nextHref="/add-listing-6"
      onNext={saveDataToLocalStorage} // Guardar datos antes de continuar
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("setHouseRules")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("guestsMustAgree")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* General Rules */}
        <div>
          <label className="text-xl font-semibold" htmlFor="">
            {t("generalRules")}
          </label>
        </div>

        {/* Pet */}
        <div>
          <label className="text-lg font-semibold" htmlFor="">
            {t("pet")}
          </label>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {renderRadio("Pet", "DoNotAllow", t("doNotAllow"))}
            {renderRadio("Pet", "Allow", t("allow"))}
            {renderRadio("Pet", "Charge", t("charge"))}
          </div>
        </div>

        {/* Party Organizing */}
        <div>
          <label className="text-lg font-semibold" htmlFor="">
            {t("partyOrganizing")}
          </label>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {renderRadio("PartyOrganizing", "DoNotAllow", t("doNotAllow"))}
            {renderRadio("PartyOrganizing", "Allow", t("allow"))}
            {renderRadio("PartyOrganizing", "Charge", t("charge"))}
          </div>
        </div>

        {/* Smoking */}
        <div>
          <label className="text-lg font-semibold" htmlFor="">
            {t("smoking")}
          </label>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {renderRadio("Smoking", "DoNotAllow", t("doNotAllow"))}
            {renderRadio("Smoking", "Allow", t("allow"))}
            {renderRadio("Smoking", "Charge", t("charge"))}
          </div>
        </div>

        {/* Additional Rules */}
        <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block text-xl font-semibold">
          {t("additionalRules")}
        </span>
        <div className="flow-root">
          <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
            {additionalRules.map((rule, index) => (
              <div
                key={index}
                className="flex items-center justify-between py-3"
              >
                <span className="text-neutral-6000 dark:text-neutral-400 text-sm font-medium">
                  {rule}
                </span>
                <i
                  className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
                  onClick={() => handleRemoveRule(index)}
                ></i>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
          <Input
            name="additionalRule"
            value={newRule}
            onChange={handleInputChange}
            className="!h-full"
            placeholder={t("addRulePlaceholder")}
          />
          <ButtonPrimary className="flex-shrink-0" onClick={handleAddRule}>
            <i className="text-xl las la-plus"></i>
            <span className="ml-3">{t("addRule")}</span>
          </ButtonPrimary>
        </div>
      </>
    </CommonLayout>
  );
};


export default PageAddListing5;
