import React, { FC, useState, useEffect, useRef } from "react";
import GallerySliderRoom from "components/GallerySlider/GallerySliderRoom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { RoomDataType } from "data/types";
import __rooms from "data/jsons/__rooms.json";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import GallerySliderRoomNoNav from "components/GallerySlider/GallerySliderRoomNoNav";

export interface RoomCardProps {
  className?: string;
  data?: RoomDataType;
  isSelected: boolean;
  onSelect: () => void;
  isSingleRoom?: boolean;
}

const DEMO_DATA = __rooms[0];

const RoomCard: FC<RoomCardProps> = ({
  className = "",
  data = DEMO_DATA,
  isSelected,
  onSelect,
  isSingleRoom = false,
}) => {
  const { galleryImgs, title, href, id, like, price } = data;
  const { t } = useTranslation("roomCard");

  const uniqueID = `RoomCard_${id || Math.random().toString(36).substr(2, 9)}`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Deshabilitar el scroll cuando el modal está abierto
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = ""; // Restaurar scroll al desmontar
    };
  }, [isModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const renderIcons = (inModal = false) => {
    const ICONS = [
      { icon: "las la-door-open", label: "Habitación" },
      { icon: "las la-ruler", label: "25 m²" },
      { icon: "las la-snowflake", label: "Aire acondicionado" },
      { icon: "las la-bath", label: "Baño en la habitación" },
      { icon: "las la-tv", label: "TV de pantalla plana" },
      { icon: "las la-volume-mute", label: "Insonorización" },
      { icon: "las la-coffee", label: "Cafetera" },
      { icon: "las la-wine-bottle", label: "Minibar" },
      { icon: "las la-wifi", label: "WiFi gratis" },
    ];

    const textColor = inModal
      ? "text-neutral-700 dark:text-neutral-300"
      : isSelected
        ? "text-white"
        : "text-neutral-700 dark:text-neutral-300";

    return (
      <div className={`flex flex-wrap gap-2 mt-2 ${textColor}`}>
        {ICONS.map((item, index) => (
          <div key={index} className="flex items-center space-x-1 text-xs">
            <i className={`${item.icon} text-sm`}></i>
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderSecondaryInfo = (inModal = false) => {
    const SECONDARY_INFO = [
      { icon: "las la-utensils", label: "Desayuno fantástico por € 25" },
      {
        icon: "las la-calendar-check",
        label: "Cancelación gratis antes del 19 de diciembre de 2024",
      },
      { icon: "las la-credit-card", label: "Pagas por adelantado" },
      { icon: "las la-signal", label: "Incluye internet de alta velocidad" },
      {
        icon: "las la-money-bill",
        label: "No pagues nada hasta el 16 de diciembre de 2024",
      },
    ];

    const textColor = inModal
      ? "text-neutral-700 dark:text-neutral-300"
      : isSelected
        ? "text-white"
        : "text-neutral-700 dark:text-neutral-300";

    return (
      <div className={`flex flex-wrap gap-2 mt-4 ${textColor}`}>
        {SECONDARY_INFO.map((item, index) => (
          <div key={index} className="flex items-center space-x-1 text-xs">
            <i className={`${item.icon} text-sm`}></i>
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderModal = () => {
    if (!isModalOpen) return null;

    return createPortal(
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        onClick={closeModal}
      >
        <div
          className="relative bg-white dark:bg-neutral-900 rounded-lg shadow-lg p-6 max-w-3xl w-full"
          onClick={(e) => e.stopPropagation()}
          ref={modalRef}
        >
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-300"
            onClick={closeModal}
          >
            ✕
          </button>
          <h2 className="text-lg font-semibold mb-4">{title}</h2>
          <GallerySliderRoom
            galleryImgs={galleryImgs}
            uniqueID={`modal_${uniqueID}`}
          />
          <div className="border-t mt-4 border-neutral-200 dark:border-neutral-700"></div>
          <div className="mt-4">{renderIcons(true)}</div>
          <div className="border-t mt-4 border-neutral-200 dark:border-neutral-700"></div>
          <div className="mt-4">{renderSecondaryInfo(true)}</div>
          <div className="mt-auto flex justify-end">
            {!isSingleRoom && (
              <button
                className={`px-3 py-2 text-xs rounded-md transition ${isSelected
                  ? "bg-white text-primary-400 border border-primary-400"
                  : "bg-primary-400 text-white hover:bg-blue-600"
                  }`}
                onClick={(e) => {
                  e.stopPropagation(); // Evita que se cierre el modal al hacer clic en el fondo
                  onSelect(); // Ejecuta la función de selección/deselección
                  closeModal(); // Cierra el modal
                }}
              >
                {isSelected ? t("selected") : t("select")}
              </button>
            )}
          </div>


        </div>
      </div>,
      document.body
    );
  };

  return (
    <div
      className={`nc-RoomCard cursor-pointer group relative border rounded-xl overflow-hidden hover:shadow-lg transition-all flex ${isSelected
        ? "bg-primary-400 text-white scale-105"
        : "bg-white dark:bg-neutral-900 border-neutral-100 dark:border-neutral-800"
        } ${className}`}
      data-nc-id="RoomCard"
      onClick={openModal} // Abre el modal al hacer clic en la tarjeta
    >
      <div className="w-40 sm:w-48 flex-shrink-0 h-auto relative">
        <div
          id={uniqueID}
          className="relative h-full w-full overflow-hidden cursor-pointer"
        >
          <GallerySliderRoomNoNav
            galleryImgs={galleryImgs}
            uniqueID={uniqueID}
            href={href}
            className="h-full w-full"
          />
        </div>
      </div>

      <div className="flex flex-col flex-grow p-4 relative">
        <div className="flex items-center justify-between mb-3">
          <h2 className="text-base font-medium capitalize line-clamp-1">
            {title}
          </h2>
        </div>
        <div className="grid grid-rows-[auto_1fr_auto] flex-grow gap-y-2">
          <div>{renderIcons()}</div>
          <div className="border-t mt-2 border-neutral-200 dark:border-neutral-700 self-center"></div>
          <div className="-mt-2">{renderSecondaryInfo()}</div>
        </div>
        <div className="mt-auto flex justify-end">
          <button
            className={`px-3 py-2 text-xs rounded-md transition ${isSelected
              ? "bg-white text-primary-400 border border-primary-400"
              : "bg-primary-400 text-white hover:bg-blue-600"
              }`}
            onClick={(e) => {
              e.stopPropagation();
              onSelect();
            }}
          >
            {isSelected ? t("selected") : t("select")}
          </button>
        </div>
      </div>

      {renderModal()}
    </div>
  );
};

export default RoomCard;