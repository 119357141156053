import React, { FC, useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface BedsAndBathroomsFiltersProps {
  setBedroomsCount: (count: number) => void;
  setBathroomsCount: (count: number) => void;
}

const BedsAndBathroomsFilters: FC<BedsAndBathroomsFiltersProps> = ({
  setBedroomsCount,
  setBathroomsCount,
}) => {
  const { t } = useTranslation("bedsAndBathroomsFilters");
  const [tempBedrooms, setTempBedrooms] = useState(0); // Estados temporales
  const [tempBathrooms, setTempBathrooms] = useState(0); // Estados temporales
  const [appliedBedrooms, setAppliedBedrooms] = useState(0); // Estados aplicados
  const [appliedBathrooms, setAppliedBathrooms] = useState(0); // Estados aplicados

  const handleIncrementBedrooms = () => {
    setTempBedrooms((prev) => prev + 1);
  };

  const handleDecrementBedrooms = () => {
    setTempBedrooms((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleIncrementBathrooms = () => {
    setTempBathrooms((prev) => prev + 1);
  };

  const handleDecrementBathrooms = () => {
    setTempBathrooms((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleApply = (close: () => void) => {
    setAppliedBedrooms(tempBedrooms); // Guarda los valores aplicados
    setAppliedBathrooms(tempBathrooms); // Guarda los valores aplicados
    setBedroomsCount(tempBedrooms);
    setBathroomsCount(tempBathrooms);
    close(); // Cierra el Popover
  };

  const handleClear = () => {
    setTempBedrooms(0); // Limpia los valores temporales
    setTempBathrooms(0); // Limpia los valores temporales
  };

  const isFilterApplied = appliedBedrooms > 0 || appliedBathrooms > 0; // Verifica si hay filtros aplicados

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-xs rounded-full border ${
              isFilterApplied
                ? "border-primary-400 text-primary-400 bg-primary-50"
                : "border-neutral-300 text-neutral-700"
            } hover:border-neutral-400 focus:outline-none ${
              open ? "!border-primary-500" : ""
            }`}
          >
            <span>{t("bedsAndBathrooms")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0">
            <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
              <div className="relative flex flex-col px-5 py-6 space-y-8">
                <div className="flex justify-between items-center">
                  <span>{t("bedrooms")}</span>
                  <div className="flex items-center">
                    <button
                      onClick={handleDecrementBedrooms}
                      className="px-2 border"
                    >
                      −
                    </button>
                    <span className="mx-2">{tempBedrooms}</span>
                    <button
                      onClick={handleIncrementBedrooms}
                      className="px-2 border"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{t("bathrooms")}</span>
                  <div className="flex items-center">
                    <button
                      onClick={handleDecrementBathrooms}
                      className="px-2 border"
                    >
                      −
                    </button>
                    <span className="mx-2">{tempBathrooms}</span>
                    <button
                      onClick={handleIncrementBathrooms}
                      className="px-2 border"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-5 bg-neutral-50 flex items-center justify-between">
                <button
                  onClick={handleClear}
                  className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full text-sm sm:text-base font-medium px-4 py-2 border border-neutral-200 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
                >
                  {t("clear")}
                </button>
                <ButtonPrimary
                  onClick={() => handleApply(close)}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  {t("apply")}
                </ButtonPrimary>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default BedsAndBathroomsFilters;
