import React, { FC, Fragment, useState, useEffect, useTransition } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import __rooms from "../../../data/jsons/__rooms.json";
import __stayListing from "../../../data/jsons/__stayListing.json"
import RoomCard from "components/RoomCard/RoomCard";
import { useParams } from "react-router-dom";
import SimpleMap from "shared/Location/SimpleMap";
import { useTranslation } from "react-i18next";
import { useTotalSum } from "contexts/TotalSumContext";
import { StarIcon } from "@heroicons/react/24/solid";
import { RoomDataType } from "data/types";
import { Helmet } from "react-helmet";
import { useCurrency } from "contexts/CurrencyContext";
import { useSelectedCard } from "contexts/SelectedCardContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useDatesContext } from "contexts/DatesContext";
import { useScrollToSection } from "contexts/ScrollToSectionContext";

interface ListingStayDetailPageProps {
  totalSumInRange: number;
}

const ITEMS_PER_PAGE = 4; // Número de reseñas por página
const TOTAL_REVIEWS = 23; // Total de reseñas disponibles
const REVIEWS = [...Array(TOTAL_REVIEWS)]; // Simula un array de reseñas

const StayDetailPageContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(1);
  const { selectedCard, setSelectedCard } = useSelectedCard();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isPending, startTransition] = useTransition();

  const { scrollToSection, setScrollToSection } = useScrollToSection();

  const { from, to } = useDatesContext();

  const { selectedCurrency } = useCurrency();

  const { totalSumInRange } = useTotalSum();
  const { setTotalSumInRange } = useTotalSum();
  const { id } = useParams();
  const { t } = useTranslation("listingStayDetailPage");
  const router = useNavigate();
  const thisPathname = useLocation().pathname;

  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();

  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [currentGalleryImages, setCurrentGalleryImages] = useState<string[]>([]);


  // Resetear la selección de tarjeta al cambiar de ficha
  useEffect(() => {
    setSelectedCard({ id: null, title: null });
  }, [id]);

  useEffect(() => {
    if (scrollToSection) {
      const section = document.getElementById(scrollToSection);
      if (section) {
        const yPosition = section.getBoundingClientRect().top + window.scrollY - 110;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
      setScrollToSection(null); // Reiniciar el estado
    }
  }, [scrollToSection, setScrollToSection]);


  // Buscar alojamiento por ID
  const stay = __stayListing.find((item) => item.id === id);

  // Filtrar habitaciones asociadas
  const associatedRooms = stay
    ? (stay.rooms as string[])
      .map((roomId) => __rooms.find((room) => room.id === roomId))
      .filter((room): room is RoomDataType => !!room)
    : [];

  const isSingleRoom = associatedRooms.length === 1;
  const showCircle = stay?.showCircle ?? false;

  // Posición predeterminada del mapa
  const position: LatLngExpression = stay
    ? [stay.map.lat, stay.map.lng]
    : [0, 0];

  // Seleccionar automáticamente la primera habitación si es única
  useEffect(() => {
    if (isSingleRoom && associatedRooms.length === 1) {
      const [firstRoom] = associatedRooms;
      if (firstRoom) {
        setSelectedCard({ id: firstRoom.id, title: firstRoom.title });
      }
    }
  }, [isSingleRoom, associatedRooms]);

  // Manejar cambio de tamaño de ventana
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calcular las reseñas visibles según la página actual
  const paginatedReviews = REVIEWS.slice(0, currentPage * ITEMS_PER_PAGE);

  // Calcular el total de páginas
  const totalPages = Math.ceil(TOTAL_REVIEWS / ITEMS_PER_PAGE);

  // Funciones de manejo
  const handleShowMore = () => {
    if (currentPage < totalPages) {
      startTransition(() => {
        setCurrentPage((prevPage) => prevPage + 1);
      });
    }
  };

  
  // Actualiza el estado de la tarjeta seleccionada al seleccionar una habitación
  const handleSelectCard = (id: string | null, title: string | null) => {
    setSelectedCard({ id, title });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModalAmenities = () => setIsOpenModalAmenities(true);
  const closeModalAmenities = () => setIsOpenModalAmenities(false);

  const handleOpenModalImageGallery = () => {
    const cleanPath = thisPathname.endsWith("/")
      ? thisPathname.slice(0, -1)
      : thisPathname;
    router(`${cleanPath}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };
  

  // Manejar render condicional después de los hooks
  if (!stay) {
    return <div>{t("noPropertyFound")}</div>;
  }

  const handleReserveClick = () => {
    if (!selectedCard.id) {
      setErrorMessage(t("mustSelectRoom"));
      return;
    }
  
    router(`/checkout/${stay.id}`); // Pasa el ID del alojamiento en la URL
  };
  
  const renderSectionRooms = () => {
    const rooms = stay.rooms as string[];

    const associatedRooms = rooms
      .map((roomId) => __rooms.find((room) => room.id === roomId))
      .filter((room): room is RoomDataType => !!room);

    return (
      <div className="listingSection__wrap mt-12">
        <h2 id="sectionRooms" className="text-2xl font-semibold">{t("roomType")}</h2>
        <div className="flex flex-col gap-8">
          {associatedRooms.map((item) => (
            <div key={item.id} className="card-pointer">
              <RoomCard
                data={item}
                isSelected={
                  associatedRooms.length === 1 || selectedCard.id === item.id
                }
                isSingleRoom={associatedRooms.length === 1}
                onSelect={() => {
                  if (associatedRooms.length === 1) return;
                  setSelectedCard((prev) =>
                    prev.id === item.id
                      ? { id: null, title: null }
                      : { id: item.id, title: item.title }
                  );
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSectionTop = () => {
    // Obtener la traducción dinámica del tipo de alojamiento
    const translatedType = t(stay.type || "unknown"); // Traduce según el tipo o usa "unknown" como fallback
  
    return (
      <div className="mb-4 !space-y-3">
        {!isMobile ? (
          // Para pantallas grandes
          <div className="flex justify-between items-center mt-7">
            <div className="flex flex-col">
              {/* Título y Badge en la misma línea */}
              <div className="flex items-center space-x-3">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  {stay.title}
                </h2>
                <Badge name={translatedType} /> {/* Badge dinámico */}
              </div>
              {/* Dirección debajo del título */}
              <div className="flex items-center space-x-2 mt-1 text-neutral-500">
                <i className="las la-map-marker-alt"></i>
                <span>{stay.address}</span>
              </div>
            </div>
    
            {/* LikeSaveBtns a la derecha */}
            <LikeSaveBtns stay={stay} />
          </div>
        ) : (
          // Para pantallas móviles
          <>
            <div className="flex justify-between items-center">
              <Badge name={translatedType} /> {/* Badge dinámico */}
              <LikeSaveBtns stay={stay} />
            </div>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mt-2">
              {stay.title}
            </h2>
            <div className="flex items-center space-x-4 mt-2">
              <span>
                <i className="las la-map-marker-alt"></i>
                <span className="ml-1">{stay.address}</span>
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderSection0 = () => {
    return (
      <div className="-mb-5">
        {/* Estrellas y número de reseñas */}
        <div className="listingSection__wrap !space-y-6">
          {/* Iconos de estrellas y número de reseñas */}
          <div className="flex items-center space-x-2">
            <StarIcon className="w-[16px] h-[16px] text-orange-500" />
            <StarIcon className="w-[16px] h-[16px] text-orange-500" />
            <StarIcon className="w-[16px] h-[16px] text-orange-500" />
            <StarIcon className="w-[16px] h-[16px] text-orange-500" />
            <StarIcon
              className="w-[16px] h-[16px] text-orange-500"
              style={{ clipPath: "inset(0 50% 0 0)" }}
            />
            <span
              className="text-xs font-semibold underline text-neutral-700 dark:text-neutral-100 cursor-pointer ml-1"
              onClick={() => {
                // Desplazarse a la sección con un offset de 100px
                const section6 = document.getElementById("section6");
                if (section6) {
                  const yPosition =
                    section6.getBoundingClientRect().top +
                    window.scrollY - 110; // 110px por encima
                  window.scrollTo({
                    top: yPosition,
                    behavior: "smooth", // Animación suave
                  });
                } else {
                  console.error("La sección 'section6' no está en el DOM.");
                }
              }}
            >
              {TOTAL_REVIEWS} {t("reviews")}
            </span>
          </div>

          {/* Títulos con íconos y texto descriptivo */}
          <div className="mt-4 space-y-3">
            {/* Ubicación fantástica */}
            <div className="flex mt-1 items-start space-x-3">
              <i className="las la-map-marker-alt text-lg text-primary-400"></i>
              <div>
                <p className="text-sm font-semibold text-neutral-700 dark:text-neutral-100">
                  Ubicación fantástica
                </p>
                <p className="text-xs text-neutral-600 dark:text-neutral-300">
                  A los huéspedes del último año les encantó donde se encuentra el alojamiento.
                </p>
              </div>
            </div>

            {/* Vistas al mar */}
            <div className="flex items-start space-x-3">
              <i className="las la-water text-lg text-primary-400"></i>
              <div>
                <p className="text-sm font-semibold text-neutral-700 dark:text-neutral-100">
                  Vistas al mar
                </p>
                <p className="text-xs text-neutral-600 dark:text-neutral-300">
                  Disfruta de vistas durante tu estancia.
                </p>
              </div>
            </div>

            {/* Cancelación gratuita */}
            <div className="flex items-start space-x-3">
              <i className="las la-check-circle text-lg text-primary-400"></i>
              <div>
                <p className="text-sm font-semibold text-neutral-700 dark:text-neutral-100">
                  Cancelación gratuita durante 48 horas
                </p>
                <p className="text-xs text-neutral-600 dark:text-neutral-300">
                  Si cambias de opinión, recibirás un reembolso completo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* Características */}
        <div
          className={`grid ${isMobile ? "grid-cols-6 gap-2" : "grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3"
            } text-sm text-neutral-700 dark:text-neutral-300`}
        >
          <div className="flex items-center space-x-2">
            <i className="las la-user text-xl"></i>
            <span>
              {stay.maxGuests}{" "}
              <span className="hidden sm:inline-block">{t("maxGuests")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-bed text-xl"></i>
            <span>
              {stay.beds} <span className="hidden sm:inline-block">{t("beds")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-bath text-xl"></i>
            <span>
              {stay.bathrooms}{" "}
              <span className="hidden sm:inline-block">{t("bathrooms")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-door-open text-xl"></i>
            <span>
              {stay.bedrooms}{" "}
              <span className="hidden sm:inline-block">{t("bedrooms")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-umbrella-beach text-xl"></i>
            <span className="hidden sm:inline-block">{t("terrace")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-swimmer text-xl"></i>
            <span className="hidden sm:inline-block">{t("heatedPool")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-arrows-alt-v text-xl"></i>
            <span className="hidden sm:inline-block">{t("elevator")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-lock text-xl"></i>
            <span className="hidden sm:inline-block">{t("safeBox")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-smoking-ban text-xl"></i>
            <span className="hidden sm:inline-block">{t("nonSmokingRooms")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-paw text-xl"></i>
            <span className="hidden sm:inline-block">{t("petsAllowed")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-utensils text-xl"></i>
            <span className="hidden sm:inline-block">{t("restaurant")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-spa text-xl"></i>
            <span className="hidden sm:inline-block">{t("spa")}</span>
          </div>
        </div>


        {/* Separador */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {t('stayDescription')}
          </span>
          <br />
          <br />
          <span>
            {t('stayDescription2')}
          </span>
          <br /> <br />
          <span>
            {t('stayDescription3')}
          </span>
        </div>
      </div>
    );
  };

  const categories = [
    {
      title: "Baño",
      icon: "la-bath",
      services: [
        "Papel higiénico",
        "Toallas",
        "Zapatillas",
        "Baño privado",
        "WC",
        "Artículos de aseo gratis",
        "Albornoz",
        "Secador de pelo",
        "Ducha",
      ],
    },
    {
      title: "Habitación",
      icon: "la-bed",
      services: [
        "Ropa de cama",
        "Armario",
        "Perchero"
      ],
    },
    {
      title: "Exteriores",
      icon: "la-building",
      services: [
        "Terraza",
        "Balcón",
        "Mobiliario exterior",
        "Sombrilla"
      ],
    },
    {
      title: "Cocina",
      icon: "la-blender",
      services: [
        "Cafetera",
        "Hervidor eléctrico",
        "Microondas",
        "Nevera"
      ],
    },
    {
      title: "Instalaciones de la habitación",
      icon: "la-plug",
      services: [
        "Enchufe cerca de la cama",
        "Mesita de noche",
        "Lámpara"
      ],
    },
    {
      title: "Mascotas",
      icon: "la-dog",
      services: [
        "Se admiten, con limitaciones",
      ],
    },
    {
      title: "Zona de estar",
      icon: "la-couch",
      services: [
        "Escritorio",
        "Silla ergonómica",
        "Sofá"
      ],
    },
    {
      title: "Equipamiento audiovisual y tecnológico",
      icon: "la-tv",
      services: [
        "TV de pantalla plana",
        "Canales vía satélite",
        "Teléfono",
        "Equipo de música"
      ],
    },
    {
      title: "Comida y bebida",
      icon: "la-pizza-slice",
      services: [
        "Cafetería en el alojamiento",
        "Fruta",
        "Vino / champán (de pago)",
        "Menú para niños",
        "Menús para dietas especiales (bajo petición)",
        "Snack-bar",
        "Desayuno en la habitación",
        "Bar",
        "Minibar",
        "Restaurante"
      ],
    },
    {
      title: "Aparcamiento",
      icon: "la-parking",
      services: [
        "Parking en garaje",
        "Estación de carga de vehículos eléctricos",
        "Parking adaptado para personas de movilidad reducida"
      ],
    },
    {
      title: "Servicios de limpieza",
      icon: "la-tshirt",
      services: [
        "Plancha",
        "Secadora",
        "Servicio de limpieza en seco (de pago)",
        "Servicio de lavandería"
      ],
    },
    {
      title: "Servicios de recepción",
      icon: "la-concierge-bell",
      services: [
        "Proporciona factura",
        "Servicio de conserjería",
        "Guarda-equipaje",
        "Información turística",
        "Cambio de moneda",
        "Recepción 24 horas",
      ],
    },
    {
      title: "Internet",
      icon: "la-wifi",
      services: ["Hay conexión a internet Wi-Fi disponible en todo el establecimiento. Gratis."],
    },
    {
      title: "Instalaciones de negocios",
      icon: "la-briefcase",
      services: [
        "Fax / fotocopiadora",
        "Salas de reuniones"
      ],
    },
    {
      title: "Seguridad",
      icon: "la-lock",
      services: [
        "Extintores",
        "Cámaras de seguridad",
        "Detectores de humo",
        "Alarma de seguridad",
        "Tarjeta de acceso",
        "Llave de acceso",
        "Código de acceso",
        "Caja fuerte"
      ],
    },
    {
      title: "Accesibilidad",
      icon: "la-wheelchair",
      services: [
        "Cuerda de emergencia en el baño",
        "Lavamanos más bajo",
        "WC elevado",
        "WC con barras de apoyo",
        "Rampas de acceso para sillas de ruedas",
        "Acceso a pisos superiores en ascensor"
      ],
    },
    {
      title: "Piscina al aire libre",
      icon: "la-swimmer",
      services: [
        "Horario de apertura",
        "Abierta todo el año",
        "Para todas las edades",
        "Piscina climatizada",
        "Toallas de playa/piscina",
        "Bar en la piscina",
        "Tumbonas",
        "Sombrillas"
      ],
    },
    {
      title: "Bienestar",
      icon: "la-spa",
      services: [
        "Gimnasio",
        "Spa",
        "Bañera de hidromasaje",
        "Sauna"
      ],
    },
    {
      title: "Idiomas",
      icon: "la-passport",
      services: [
        "Inglés",
        "Español",
        "Francés",
        "Italiano"
      ],
    },
  ];

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t('amenities')}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-4"></div>

        {/* Contenedor con CSS Columns equilibrado */}
        <div
          className="columns-2 gap-x-8 text-sm text-neutral-700 dark:text-neutral-300"
          style={{ columnFill: "balance" }}
        >
          {categories.map((category, index) => (
            <div key={index} className="mb-6 break-inside-avoid">
              {/* Título de la Categoría */}
              <div className="flex items-center space-x-2 mb-3">
                <i className={`text-xl las ${category.icon}`}></i>
                <h3 className="font-bold text-base">{category.title}</h3>
              </div>

              {/* Lista de Servicios */}
              <ul className="space-y-1">
                {category.services.map((service, i) => (
                  <li key={i} className="flex items-center space-x-2">
                    <i className="las la-check-circle text-green-500"></i>
                    <span className="text-xs">{service}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">{t("hostInformation")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 {t("nrOfPlaces")}</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {t("hostDescription")}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>{t("joinedIn")}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>{t("responseRate")}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>{t("responseTime")}</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">{t("seeHostProfile")}</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = (
  ) => {
    return (
      <div className="listingSection__wrap">
        {/* Encabezado de la sección */}
        <h2 id="section6" className="text-2xl font-semibold">
          {t("reviews")} ({TOTAL_REVIEWS})
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Lista de comentarios */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {paginatedReviews.map((_, index) => (
            <CommentListing key={index} className="py-8" />
          ))}
        </div>

        {/* Botón "Show more reviews" */}
        {currentPage < totalPages && (
          <div className="flex mt-10 justify-center">
            <ButtonPrimary onClick={handleShowMore} disabled={isPending}>
              {isPending ? t("loading") : t("showMoreButton")}
            </ButtonPrimary>
          </div>
        )}
      </div>
    );
  };


  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t('location')}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {stay.title}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          {/* Usa SimpleMap aquí */}
          <SimpleMap lat={stay.map.lat} lng={stay.map.lng} />
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    const formatPrice = (price: number): string => {
      const convertedPrice = price * selectedCurrency.rate;
      const formattedPrice =
        convertedPrice % 1 === 0
          ? convertedPrice.toFixed(0)  // Sin decimales si es número entero
          : convertedPrice.toFixed(2); // Con dos decimales si no es entero
    
      return selectedCurrency.symbolPosition === "before"
        ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
        : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
    };    
  
    return (
      <div className="listingSectionSidebar__wrap shadow-xl max-w-[400px]">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-2xl font-semibold">{selectedCard.title}</span>
        </div>
  
        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <StayDatesRangeInput className="flex-1 z-[11]" isSelected={selectedCard.id !== null} />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" maxGuests={stay.maxGuests} />
        </form>
  
        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("stayCharge")}</span>
            <span>{formatPrice(totalSumInRange)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("serviceCharge")}</span>
            <span>{formatPrice(0)}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("total")}</span>
            <span>{formatPrice(totalSumInRange)}</span>
          </div>
        </div>
  
        {/* SUBMIT */}
        <div className="mt-6 flex flex-col items-center">
          <ButtonPrimary
            onClick={() => {
              if (selectedCard.id === null) {
                const sectionRooms = document.getElementById("sectionRooms");
                if (sectionRooms) {
                  const yPosition =
                    sectionRooms.getBoundingClientRect().top + window.scrollY - 110;
                  window.scrollTo({ top: yPosition, behavior: "smooth" });
                }
                return;
              }
              handleReserveClick();
            }}
            className="w-full max-w-sm py-3"
          >
            {selectedCard.id === null ? t("mustSelectRoom") : t("reserve")}
          </ButtonPrimary>
        </div>
      </div>
    );
  };
  

  return (
    <div
      className={`nc-ListingStayDetailPage ${isMobile ? "pt-5" : "pt-3"
        }`}
    >
      <Helmet>
  <title>{stay.title || "Default Title"}</title>
  <meta property="og:title" content={stay.title || "Default Title"} />
  <meta 
    property="og:description" 
    content={`Check out this amazing stay at ${stay.address || "our amazing location"}!`} 
  />
  <meta
    property="og:image"
    content={
      stay.featuredImage || 
      stay.galleryImgs?.[0] || 
      `${window.location.origin}/default-image.jpg`
    }
  />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:url" content={`${window.location.origin}/listing-stay-detail/${stay.id}`} />
  <meta property="og:type" content="website" />
</Helmet>
      {renderSectionTop()}
      {/* HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          {/* Imagen principal */}
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={stay.galleryImgs[0]} // Imagen principal
              alt={stay.title}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/* Imágenes secundarias */}
          {stay.galleryImgs.slice(1, 5).map((img, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={img}
                  alt={`${stay.title} - Image ${index + 1}`}
                  sizes="400px"
                />
              </div>

              {/* Overlay */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          {/* Botón "Show all photos" */}
          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              {t("showAllPhotos")}
            </span>
          </button>
        </div>
      </header>


      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection0()}
          {renderSection1()}
          {renderSectionRooms()}
          <SectionDateRange
            id="sectionDateRange"
            onTotalSumChange={setTotalSumInRange}
            isSelected={selectedCard.id !== null}
          />
          {renderSection3()}
          {renderSection6()}
          {renderSection7()}
          {renderSection5()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}