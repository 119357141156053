import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Leer el idioma desde localStorage o usar 'es' como idioma por defecto
const defaultLanguage = localStorage.getItem("langIsoCode") || "es";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/components/{{ns}}.json", // Ajusta la ruta para que cargue desde /public/locales
    },
    lng: defaultLanguage, // Utiliza el idioma desde localStorage
    fallbackLng: "en", // Idioma de respaldo
    debug: true, // Deshabilita en producción
    ns: ["sectionHero", "tabFilters", "pageLogin", "errors"], // Namespaces disponibles
    defaultNS: "sectionHero", // Namespace por defecto
    interpolation: {
      escapeValue: false, // React ya escapa los valores
    },
    pluralSeparator: "_", // Habilita el uso de claves singulares y plurales
    react: {
      useSuspense: true, // Activa Suspense para manejar la carga de traducciones
    },
  });

export default i18n;
