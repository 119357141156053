import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, useState } from "react";
import DatePickerCustomHeaderTwoMonth2 from "./DatePickerCustomHeaderTwoMonth2";
import DatePickerCustomDay from "../containers/ListingDetailPage/DatePickerCustomDay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";
import { useDatesContext } from "contexts/DatesContext";

// Registro de idiomas para react-datepicker
registerLocale("es", es);
registerLocale("en-US", enUS);

interface ModalSelectDateProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const ModalSelectDate: FC<ModalSelectDateProps> = ({ renderChildren }) => {
  const { t } = useTranslation("modalSelectDate");
  const { from: startDate, to: endDate, setFrom: setStartDate, setTo: setEndDate } = useDatesContext(); // Usamos el contexto

  const [showModal, setShowModal] = useState(false);

  const generatedNumbers: { [key: string]: number } = {};

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // Mapeo de idiomas de i18n a los idiomas registrados
  const localeMap: Record<string, string> = {
    es: "es",
    "en-US": "en-US",
  };

  const currentLocale = localeMap[i18n.language] || "en-US";

  // Manejo del cierre del modal
  function closeModal() {
    setShowModal(false);
  }

  // Manejo de la apertura del modal
  function openModal() {
    setShowModal(true);
  }

  // Renderiza el botón para abrir el modal
  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>{t("selectDate")}</button>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              {t("whensYourTrip")}
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10">
                            <div className="overflow-hidden rounded-3xl">
                              <DatePicker
                                selected={startDate}
                                onChange={onChangeDate}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                minDate={new Date()}
                                monthsShown={2}
                                showPopperArrow={false}
                                inline
                                locale={currentLocale} // Configura el idioma actual
                                calendarStartDay={1} // Inicia la semana en lunes
                                renderCustomHeader={(p) => (
                                  <DatePickerCustomHeaderTwoMonth2 {...p} />
                                )}
                                renderDayContents={(day, date) => (
                                  <DatePickerCustomDay
                                    dayOfMonth={day}
                                    date={date}
                                    selected={
                                      !!(
                                        startDate &&
                                        endDate &&
                                        date &&
                                        date >= startDate &&
                                        date <= endDate
                                      )
                                    }
                                    generatedNumbers={generatedNumbers}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {
                          onChangeDate([null, null]);
                        }}
                      >
                        {t("clearDates")}
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        {t("save")}
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectDate;
