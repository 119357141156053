import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useRef } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";

export interface GallerySliderRoomProps {
  className?: string;
  galleryImgs: string[];
  uniqueID: string;
  href?: string;
}

const GallerySliderRoom: FC<GallerySliderRoomProps> = ({
  className = "",
  galleryImgs,
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
  const glideRef = useRef<HTMLDivElement>(null);
  const glideInstance = useRef<Glide | null>(null);

  useEffect(() => {
    if (glideRef.current) {
      glideInstance.current = new Glide(`.${UNIQUE_CLASS}`, {
        perView: 1,
        gap: 0,
        keyboard: false,
      });

      glideInstance.current.mount();

      return () => {
        glideInstance.current?.destroy();
      };
    }
  }, [UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => (
    <div
      className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
      data-glide-el="controls[nav]"
    >
      {galleryImgs.map((_, i) => (
        <button
          className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
          key={i}
          data-glide-dir={`=${i}`}
        />
      ))}
    </div>
  );

  const renderSliderGallery = () => (
  <div
    ref={glideRef}
    className={`${UNIQUE_CLASS} relative group overflow-hidden w-full h-full`}
  >
    <div className="glide__track h-full" data-glide-el="track">
      <ul className="glide__slides h-full">
        {galleryImgs.map((item, index) => (
          <li key={index} className="glide__slide h-full w-full">
            {/* Simple contenedor para la imagen */}
            <div className="block w-full h-full">
              <img
                src={item}
                alt={`slide-${index}`}
                className="w-full h-full object-cover"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  


      {/* Dots */}
      <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
      {renderDots()}

      {/* Navigation */}
      <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
        <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
      </div>
    </div>
  );

  return (
    <div
      className={`nc-GallerySlider ${className} w-full h-full`}
      data-nc-id="GallerySlider"
    >
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySliderRoom;
