import React, { FC, useEffect, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import { useFiltersContext } from "contexts/FilterContext";
import { useLocationContext } from "contexts/LocationContext";
import { useGuestsContext } from "contexts/GuestsContext";

export interface SectionGridHasMapProps {
  isMapVisible: boolean;
  onToggleMap: () => void;
  mapCenter?: [number, number];
}

const ITEMS_PER_PAGE = 6;

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  isMapVisible,
  onToggleMap,
  mapCenter,
}) => {
  const { t, i18n } = useTranslation("heading2");
  const { from, to } = useDatesContext();
  const { location } = useLocationContext();
  const { adults, children, infants } = useGuestsContext();
  const {
    priceRange,
    selectedTypes,
    bedroomsCount,
    bathroomsCount,
    selectedAmenities,
    selectedFacilities,
    sortBy,
  } = useFiltersContext();

  const totalGuests = adults + children + infants;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detecta si es móvil
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [visibleStays, setVisibleStays] = useState(DEMO_STAY_LISTINGS.slice(0, ITEMS_PER_PAGE));
  const [currentIndex, setCurrentIndex] = useState(ITEMS_PER_PAGE);

  // Manejar redimensionamiento
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const handleScroll = () => {
    const margin = 400; // Margen de anticipación en píxeles
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - margin) {
      loadMoreItems();
    }
  };  

  const loadMoreItems = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + ITEMS_PER_PAGE;
      setVisibleStays((prevStays) => [
        ...prevStays,
        ...sortedStays.slice(prevIndex, newIndex),
      ]);
      return newIndex;
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentIndex, visibleStays]);

  // Filtrar alojamientos según los filtros seleccionados
  const filteredStays = DEMO_STAY_LISTINGS.filter((stay) => {
    const matchesType = selectedTypes.length === 0 || selectedTypes.includes(stay.type);
    const matchesBedrooms = bedroomsCount === 0 || stay.bedrooms >= bedroomsCount;
    const matchesBathrooms = bathroomsCount === 0 || stay.bathrooms >= bathroomsCount;
    const matchesAmenities =
      selectedAmenities.length === 0 || selectedAmenities.every((amenity) => stay.amenities.includes(amenity));
    const matchesFacilities =
      selectedFacilities.length === 0 || selectedFacilities.every((facility) => stay.facilities.includes(facility));

    return (
      stay.price >= priceRange[0] &&
      stay.price <= priceRange[1] &&
      matchesType &&
      matchesBedrooms &&
      matchesBathrooms &&
      matchesAmenities &&
      matchesFacilities
    );
  });

  const sortedStays = [...filteredStays].sort((a, b) => {
    if (sortBy === "lowestPrice") return a.price - b.price;
    if (sortBy === "highestPrice") return b.price - a.price;
    return 0;
  });

  useEffect(() => {
    setVisibleStays(sortedStays.slice(0, ITEMS_PER_PAGE));
    setCurrentIndex(ITEMS_PER_PAGE);
  }, [priceRange, selectedTypes, bedroomsCount, bathroomsCount, selectedAmenities, selectedFacilities]);

  // Diseño para móvil: Mostrar solo el mapa en pantalla completa
  if (isMobile && isMapVisible) {
    return (
      <div className="fixed inset-0 z-49 bg-white">
        {/* Mapa en pantalla completa */}
        <div className="w-full h-full z-[50]">
          <MapContainer
            currentHoverID={currentHoverID}
            DEMO_DATA={filteredStays.length ? filteredStays : []}
            listingType="stay"
            mapCenter={mapCenter} // Pasar el centro del mapa al componente
          />
        </div>
      </div>
    );
  }

  // Diseño para escritorio
  return (
    <div className="relative flex min-h-screen">
      <div className="w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 relative z-20">
        <Heading2
          onToggleMap={onToggleMap}
          isMapVisible={isMapVisible}
          heading={`${t("staysIn")} ${location || t("destination")}`}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {from ? new Date(from).toLocaleDateString(i18n.language) : t("checkIn")} -{" "}
              {to ? new Date(to).toLocaleDateString(i18n.language) : t("checkOut")}
              <span className="mx-2">·</span>
              {t("numberOfGuests", { count: totalGuests })}
            </span>
          }
        />
        <div className="mb-8 lg:mb-11">
          <TabFilters
            onMapClick={onToggleMap} // Pasa la función para abrir/cerrar el mapa
            isMapOpen={isMapVisible} // Pasa el estado del mapa
          />
        </div>
        <div className="grid grid-cols-1 gap-8">
          {visibleStays.length === 0 ? (
            <p>{t("noCards")}</p>
          ) : (
            visibleStays.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID(item.id)}
                onMouseLeave={() => setCurrentHoverID(-1)}
              >
                <StayCardH data={item} />
              </div>
            ))
          )}
        </div>
      </div>

      {isMapVisible && !isMobile && (
        <div className="flex-grow xl:ml-8 h-full xl:h-[calc(100vh-88px)] sticky top-0">
          <div className="w-full h-full rounded-md overflow-hidden">
            <MapContainer
              currentHoverID={currentHoverID}
              DEMO_DATA={filteredStays.length ? filteredStays : []}
              listingType="stay"
              mapCenter={mapCenter} // centro del mapa
            />
            <ButtonClose
              onClick={onToggleMap}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
