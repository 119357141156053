import React, { FC, useEffect, useState } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext"; // Importa el contexto de autenticación
import { useNavigate } from "react-router-dom";

export interface PageAddListing6Props {}

const PageAddListing6: FC<PageAddListing6Props> = () => {
  const { t } = useTranslation("pageAddListing6");
  const { isAuthenticated } = useAuth(); // Verifica si el usuario está autenticado
  const navigate = useNavigate();
  const [description, setDescription] = useState<string>("");

  // Cargar la descripción desde localStorage al iniciar
  useEffect(() => {
    const storedDescription = localStorage.getItem("pageAddListing6Description");
    if (storedDescription) {
      setDescription(storedDescription);
    }
  }, []);

  // Función para guardar la descripción en localStorage cuando se hace clic en "Continuar"
  const handleSave = () => {
    localStorage.setItem("pageAddListing6Description", description);
  };

  // Manejar cambios en el textarea
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <CommonLayout
      index="06"
      backtHref="/add-listing-5"
      nextHref="/add-listing-7"
      onNext={handleSave} // Guardar el texto antes de continuar
      onBeforeNext={() => {
        if (!isAuthenticated) {
          localStorage.setItem("redirectAfterLogin", "/add-listing-7"); // Guarda la URL de destino
          navigate("/login");
          return false; // Detén la navegación
        }
        return true; // Permite continuar si está autenticado
      }}
      
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("yourPlaceDescription")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("descriptionInstructions")}
          </span>
        </div>

        <Textarea
          placeholder={t("textareaPlaceholder")}
          rows={14}
          value={description} // Valor del textarea
          onChange={handleTextareaChange} // Manejar cambios
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
