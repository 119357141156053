import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "contexts/CurrencyContext"; 
import { Currency } from "contexts/CurrencyContext"; 
import {
  CurrencyDollarIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyRupeeIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";


export default function CurrencyDropdown() {
  const { t } = useTranslation("languageAndCurrency");
  const { selectedCurrency, setSelectedCurrency } = useCurrency();

  // Lista de monedas del contexto 
  const currencies: Currency[] = [
    { id: "EUR", name: "currency.euro", symbol: "€", rate: 0.9, icon: CurrencyEuroIcon, symbolPosition: "after" },
    { id: "USD", name: "currency.dollar", symbol: "$", rate: 1, icon: CurrencyDollarIcon, symbolPosition: "before" },
    { id: "GBP", name: "currency.pound", symbol: "£", rate: 0.8, icon: CurrencyPoundIcon, symbolPosition: "before" },
    { id: "INR", name: "currency.rupee", symbol: "₹", rate: 74, icon: CurrencyRupeeIcon, symbolPosition: "before" },
    { id: "SAR", name: "currency.riyal", symbol: "﷼", rate: 3.75, icon: BanknotesIcon, symbolPosition: "before" },
  ];  

  return (
    <div className="CurrencyDropdown relative">
      <Popover className="relative z-50">
        {({ open, close }) => ( 
          <>
            {/* Botón de selección de moneda */}
            <Popover.Button
              aria-haspopup="listbox"
              aria-expanded={open}
              aria-label={t("currencySelector")}
              className={`${
                open ? "" : "text-opacity-80"
              } group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none`}
            >
              <selectedCurrency.icon className="w-5 h-5 opacity-80" />
              <span className="ml-2 select-none">{t(selectedCurrency.name)}</span>
              <ChevronDownIcon
                className={`${
                  open ? "-rotate-180" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>

            {/* Panel desplegable */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-50 w-screen max-w-[200px] px-4 mt-4 right-0 sm:px-0 overflow-visible"
                style={{ position: "absolute", top: "100%", right: "0" }}
              >
                <div className="overflow-visible rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-7 bg-white dark:bg-neutral-800 p-7">
                    {/** Mapear todas las monedas del contexto */}
                    {currencies.map((currency: Currency) => (
                      <button
                        key={currency.id}
                        onClick={() => {
                          setSelectedCurrency(currency); // Cambia la moneda seleccionada
                          close(); // Cierra el dropdown después de seleccionar
                        }}
                        className={`flex items-center p-2 -m-3 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          selectedCurrency.id === currency.id
                            ? "bg-gray-100 dark:bg-neutral-700 font-bold"
                            : "opacity-80"
                        }`}
                      >
                        <currency.icon className="w-[18px] h-[18px]" />
                        <span className="ml-2 text-sm font-medium">{t(currency.name)}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
