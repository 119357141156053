import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInputSmall";
import GuestsInput from "../GuestsInputSmall";
import StayDatesRangeInput from "./StayDatesRangeInputSmall";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useLocationContext } from "contexts/LocationContext";

export interface StaySearchFormProps {
  defaultFieldFocus?: StaySearchFormFields;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckoutSelected: () => void;
  onValidate?: (errors: { locationError: boolean; dateError: boolean }) => void;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  defaultFieldFocus,
  searchValue,
  onSearchChange,
  onCheckoutSelected,
  onValidate,
}) => {
  const { from, to, setFrom, setTo } = useDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();
  const { location, setLocation } = useLocationContext();

  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isDateInputOpen, setIsDateInputOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  // Guardar filtro en localStorage
  const saveSearchFilterToLocalStorage = () => {
    const searchFilter = {
      location,
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      adult: adults,
      children,
      infant: infants,
    };
    localStorage.setItem("searchFilter", JSON.stringify(searchFilter));
  };

  // Sincronizar con localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      setFrom(savedFilter.from ? new Date(savedFilter.from) : null);
      setTo(savedFilter.to ? new Date(savedFilter.to) : null);
      setLocation(savedFilter.location || "");
      setAdults(savedFilter.adult || 1);
      setChildren(savedFilter.children || 0);
      setInfants(savedFilter.infant || 0);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setFrom, setTo, setAdults, setChildren, setInfants, setLocation]);

  // Cerrar todos los modales
  const closeAllModals = () => {
    setIsLocationOpen(false);
    setIsDateInputOpen(false);
    setIsGuestsOpen(false);
  };

  // Manejar apertura de modales
  const handleOpenLocation = () => {
    closeAllModals();
    setIsLocationOpen(true);
  };

  const handleOpenDates = () => {
    closeAllModals();
    setIsDateInputOpen(true);
  };

  const handleOpenGuests = () => {
    closeAllModals();
    setIsGuestsOpen(true);
  };

  // Manejar selección de ubicación
  const handleLocationSelected = (selectedLocation: string) => {
    setLocation(selectedLocation);
    saveSearchFilterToLocalStorage();

    // Secuencia lógica: cerrar ubicación y abrir fechas
    closeAllModals();
    setIsDateInputOpen(true);
  };

  // Manejar cambios de fecha
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setFrom(startDate);
    setTo(endDate);
    saveSearchFilterToLocalStorage();

    // Si ambas fechas están seleccionadas, cerrar modal de fechas y abrir modal de huéspedes
    if (startDate && endDate) {
      setIsDateInputOpen(false); // Cerrar fechas
      setTimeout(() => {
        handleOpenGuests(); // Usar función centralizada para abrir Guests
      }, 100); // Garantizar que se cierra antes de abrir el siguiente modal
    }
  };

  // Validar campos obligatorios
  const validateFields = () => {
    let isValid = true;

    if (!location) {
      handleOpenLocation();
      isValid = false;
    } else if (!from || !to) {
      handleOpenDates();
      isValid = false;
    }

    return isValid;
  };

  // Manejar cierre con Escape o clic fuera
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest(".location-input") &&
        !target.closest(".date-input") &&
        !target.closest(".guests-input")
      ) {
        closeAllModals();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <form className="relative flex rounded-full border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-1">
      <LocationInput
        className="flex-[1.5] location-input"
        isOpen={isLocationOpen}
        onLocationSelected={handleLocationSelected}
        onOpen={handleOpenLocation}
      />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

      <StayDatesRangeInput
        className="flex-[1.1] date-input"
        isOpen={isDateInputOpen}
        onDatesChange={handleDateChange}
        onOpen={handleOpenDates}
        onClose={() => setIsDateInputOpen(false)}
      />

      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

      <GuestsInput
        className="flex-[1.5] guests-input"
        isOpen={isGuestsOpen}
        onOpen={handleOpenGuests} // Usar la función centralizada
        buttonSubmitHref="/search"
        validateFields={validateFields}

      />
    </form>
  );
};

export default StaySearchForm;
