import React, { FC, Suspense } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { useCurrency } from "contexts/CurrencyContext";
import { useTranslation } from "react-i18next";

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const { t } = useTranslation("stayCard"); // Namespace para las traducciones de stayCard
  const { t: tTabFilters } = useTranslation("tabFilters"); // Namespace para las traducciones de tabFilters

  const {
    galleryImgs = [],
    listingCategory = { name: t("category") },
    address = t("unknownAddress"),
    title = t("noTitle"),
    bedrooms = 1,
    id = "",
    like = false,
    saleOff = false,
    isAds = false,
    price = "0",
    reviewStart = 0,
    reviewCount = 0,
    type = "",
  } = data || {};

  const { selectedCurrency } = useCurrency();

  // Convertir y formatear el precio
  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === "number" ? price : parseFloat(price);
    if (isNaN(numericPrice) || !selectedCurrency.rate) {
      return `${selectedCurrency.symbol}N/A`;
    }
    const convertedPrice = numericPrice * selectedCurrency.rate;
    const formattedPrice =
      convertedPrice % 1 === 0
        ? Math.floor(convertedPrice).toString()
        : convertedPrice.toFixed(2);
  
    return selectedCurrency.symbolPosition === "before"
      ? `${selectedCurrency.symbol}${formattedPrice}`  // Símbolo antes del número
      : `${formattedPrice}${selectedCurrency.symbol}`; // Símbolo después del número
  };
  

  // Mapeo de valores `type` a claves de traducción
  const typeMapping: { [key: string]: string } = {
    apartment: "apartment",
    house: "house",
    villa: "villa",
    hotel: "hotel",
    inn: "inn",
    motel: "motel",
    boat: "boat",
    sharedRoom: "sharedRoom",
    privateRoom: "privateRoom",
    hotelRoom: "hotelRoom",
  };

  // Obtener el nombre traducido del tipo de alojamiento
  const translatedType = typeMapping[type]
    ? tTabFilters(typeMapping[type])
    : tTabFilters("unknown");

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <Suspense fallback={<div>{t("loadingGallery")}</div>}>
          <GallerySlider
            uniqueID={`StayCard_${id}`}
            ratioClass="aspect-w-4 aspect-h-3"
            galleryImgs={galleryImgs}
            href={`/listing-stay-detail/${id}`}
          />
        </Suspense>
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />
        {saleOff && typeof saleOff === "string" && saleOff.trim() !== "" && (
          <SaleOffBadge className="absolute left-3 top-3" desc={saleOff} />
        )}
      </div>
    );
  };

  const renderContent = () => (
    <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
      <div className="space-y-2">
        <span className="text-sm text-neutral-500 dark:text-neutral-400">
          {translatedType} ·{" "}
          {bedrooms === 1
            ? t("bedrooms", { count: 1 })
            : t("bedrooms_plural", { count: bedrooms })}
        </span>

        <div className="flex items-center space-x-2">
          {isAds && <Badge name={t("ads")} color="green" />}
          <h2
            className={`font-medium capitalize ${
              size === "default" ? "text-lg" : "text-base"
            }`}
          >
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === "default" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span>{address}</span>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
      <div className="flex justify-between items-center">
        <span className="text-base font-semibold">
          {formatPrice(price)}
          {` `}
          {size === "default" && (
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              {t("perNight")}
            </span>
          )}
        </span>
        {!!reviewStart && (
          <Suspense fallback={<div>{t("loadingRating")}</div>}>
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          </Suspense>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/listing-stay-detail/${id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;